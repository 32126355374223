// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* sidebar.css */

.sidebar-container {
  height: 100vh;
  max-height: 100%; /* Set a maximum height for smaller screens */
  overflow-y: auto; /* Add this to enable vertical scrolling if the content overflows */
  width: 8%;
  min-width: 150px;
}

.sidebar-row {
  height: 100%; /* Set the row height to 100% of the parent container */
}

.sidebar {
  background-color: #000;
  height: 100%;
  position: sticky;
  top: 0;
}

.logo {
  max-width: 80%;
}

.hr {
  background-color: white;
}

.link-og {
  width: 60px;
  transition: width 0.5s ease-in-out;
}

.link-og:hover,
.logout:hover {
  background-color: rgb(64, 64, 242);
  border-radius: 20px;
}

.link-og.active,
.logout.active {
  background-color: rgb(64, 64, 242);
  border-radius: 20px;
}

.logout {
  position: absolute;
  bottom: 20px;
  left: 30%;
  width: 60px;
  height: 60px;
  transition: width 0.5s ease-in-out;
}

.ul-gap {
  gap: 10px;
}

@media (max-width: 1001px) {
  .sidebar-container {
    width: 6%;
  }
}`, "",{"version":3,"sources":["webpack://./src/sidebar/sidebar.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;EACE,aAAa;EACb,gBAAgB,EAAE,6CAA6C;EAC/D,gBAAgB,EAAE,mEAAmE;EACrF,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,YAAY,EAAE,uDAAuD;AACvE;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,MAAM;AACR;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kCAAkC;AACpC;;AAEA;;EAEE,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;;EAEE,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":["/* sidebar.css */\r\n\r\n.sidebar-container {\r\n  height: 100vh;\r\n  max-height: 100%; /* Set a maximum height for smaller screens */\r\n  overflow-y: auto; /* Add this to enable vertical scrolling if the content overflows */\r\n  width: 8%;\r\n  min-width: 150px;\r\n}\r\n\r\n.sidebar-row {\r\n  height: 100%; /* Set the row height to 100% of the parent container */\r\n}\r\n\r\n.sidebar {\r\n  background-color: #000;\r\n  height: 100%;\r\n  position: sticky;\r\n  top: 0;\r\n}\r\n\r\n.logo {\r\n  max-width: 80%;\r\n}\r\n\r\n.hr {\r\n  background-color: white;\r\n}\r\n\r\n.link-og {\r\n  width: 60px;\r\n  transition: width 0.5s ease-in-out;\r\n}\r\n\r\n.link-og:hover,\r\n.logout:hover {\r\n  background-color: rgb(64, 64, 242);\r\n  border-radius: 20px;\r\n}\r\n\r\n.link-og.active,\r\n.logout.active {\r\n  background-color: rgb(64, 64, 242);\r\n  border-radius: 20px;\r\n}\r\n\r\n.logout {\r\n  position: absolute;\r\n  bottom: 20px;\r\n  left: 30%;\r\n  width: 60px;\r\n  height: 60px;\r\n  transition: width 0.5s ease-in-out;\r\n}\r\n\r\n.ul-gap {\r\n  gap: 10px;\r\n}\r\n\r\n@media (max-width: 1001px) {\r\n  .sidebar-container {\r\n    width: 6%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
