import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./Login/Login";
import Overview from "./Overview/Overview";
import Analytics from "./analytics/Analytics";
import Report from "./report/Report";
import AddDrum from "./addDrum/AddDrum";
import { useSelector } from "react-redux";
import DownloadExcel from "./report/Popup/DownloadExcel";
import DownloadPDF from "./report/Popup/DownloadPDF";
import { setUserDetailsInLocalStorage } from "./Components/LocalStorage";
function App() {
  const userDetails = useSelector((state) => state.userData);
  console.log(userDetails);
  // const navigate=useNavigate();
  useEffect(() => {
    if (userDetails) {
      setUserDetailsInLocalStorage(userDetails);
    }
  }, [userDetails]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/download-excel" element={<DownloadExcel />} />
        <Route path="/download-pdf" element={<DownloadPDF />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/report" element={<Report />} />
        <Route path="/add-drum" element={<AddDrum />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// return (
//   <BrowserRouter>
//     <Routes>
//       {/* Render the login route if not authenticated */}
//       {!isAuthenticated && <Route path="/" element={<Login />} />}

//       {/* Render the protected routes only when isAuthenticated is true */}
//       {isAuthenticated ? (
//         <>
//           <Route path="/overview" element={<Overview />} />
//           <Route path="/analytics" element={<Analytics />} />
//           <Route path="/report" element={<Report />} />
//         </>
//       ) : (
//         // Redirect to login if not authenticated
//         <Route path="/" element={<Navigate to="/" replace />} />
//       )}
//     </Routes>
//   </BrowserRouter>
// );
