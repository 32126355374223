import React, { useState, useEffect } from "react";
import "./Main.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VerifyPopup from "../Popup/YesPopup";
import {
  fetchData,
  fetchDrumsData,
  drumsData,
} from "../../redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { FaCheck } from "react-icons/fa";
import { connect } from "react-redux";
import { FaTimes } from "react-icons/fa";
import DeletePopup from "../Popup/NoPopup";
import DownloadPopup from "../Popup/DownloadPopup";
import Calendar from "react-calendar";
import NoRecords from "../NoRecords/NoRecords";
import { SlArrowDown } from "react-icons/sl";
import { BASE_URL } from "../../baseurl";
import { LuMaximize2 } from "react-icons/lu";
import { printPdf } from "../Download/GenerateTablePdf";
import { fetchDefectData } from "../../redux/actions";
import clockIcon from "../../images/clock-icon.svg";
import machineStoppedIcon from "../../images/machineStopped.svg";
import Popup from "reactjs-popup";
import { MdCheck, MdClose } from "react-icons/md";
import "react-calendar/dist/Calendar.css";

const columns = [
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
  {
    id: "cage",
    label: "Cage",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "type",
    label: "Defect type",
    format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //   id: "verify",
  //   label: "Verify",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "type",
    label: "Drum",
  },
  {
    id: "image",
    label: "Image",
  },
];
const formatDate=(date)=>{
  const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
  const indianTime = new Date(date.getTime() + indianTimeOffset);
  const formattedDate = indianTime.toISOString().split("T")[0];
  return formattedDate;
}
const Main = ({
  fetchData,
  fetchDrumsData,
  drumsData,
  tableData,
  selectedRST,
  fetchDefectData,
  tableDataLoading,
  defects,
}) => {
  const [currentImgUrl, setCurrentImgUrl] = useState("");
  const [showYesPopup, setShowYesPopup] = useState(false);
  const [showNoPopup, setShowNoPopup] = useState(false);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [selectedCage, setSelectedCage] = useState("");
  const [selectedDateString, setSelectedDateString] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMachineProcess, setSelectedMachineProcess] = useState(null);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [refresh, setRefresh] = useState(new Date());
  const [selectedDefect, setSelectedDefect] = useState("");
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [uniqueDefectsArr, setUniqueDefectsArr] = useState([]);
  const uniqueDefects = [];
  const [selectedAccuracy, setSelectedAccuracy] = useState("");
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const toggleDownloadPopup = () => {
    setShowDownloadPopup(!showDownloadPopup);
    setDownloadOpen(false);
  };

  useEffect(() => {
    fetchDefectData();
  }, []);
  // useEffect(()=>{
  //   setUniqueDefectsArr(uniqueDefects)
  // },[uniqueDefects])
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCalenderOpen(false);
  };
  const handleShiftChange = (event) => {
    const selectedShift = event.target.value;
    setSelectedShift(selectedShift);
  };
  const handleSelectedDefectChange = (event) => {
    const selectedDefect = event.target.value;
    setSelectedDefect(selectedDefect);
  };

  const handleCageChange = (event) => {
    const selectedCage = event.target.value;
    setSelectedCage(selectedCage);
  };

  const handleOutsideClick = (event) => {
    if (
      calenderOpen &&
      !event.target.closest(".date-button") &&
      !event.target.closest(".calender-container")
    ) {
      setCalenderOpen(false); // Close the calendar if clicked outside
    }
  };

  // Use the handleOutsideClick function in useEffect
  useEffect(() => {
    if (calenderOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [calenderOpen]);
  useEffect(() => {
    const selectedDateString = `${selectedDate.getFullYear()}/${(
      selectedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${selectedDate.getDate().toString().padStart(2, "0")}`;
    setSelectedDateString(selectedDateString);
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
      const indianTime = new Date(selectedDate.getTime() + indianTimeOffset);
      const formattedDate = indianTime.toISOString().split("T")[0]; // Use indianTime for formatting
      console.log(indianTime);
      fetchData(formattedDate);
      fetchDrumsData(formattedDate);
      const intervalId = setInterval(() => {
        setRefresh(new Date());
      }, 180000);

      return () => clearInterval(intervalId);
    } else {
      fetchData(); // If no date is selected, fetch data for today's date
    }
  }, [fetchData, selectedDate, refresh, fetchDrumsData]);
  console.log("uniqueDefects", uniqueDefects);

  const handleMachineStart = () => {
    setSelectedMachineProcess((prevState) =>
      prevState === "START" ? null : "START"
    );
  };

  const handleMachineStop = () => {
    setSelectedMachineProcess((prevState) =>
      prevState === "STOP" ? null : "STOP"
    );
  };
  console.log("selectedMachineProcess", selectedMachineProcess);
  useEffect(() => {
    if (!tableData) return; // If tableData is not yet available, do nothing



tableData.forEach((defect) => {
  const defectDateTime = new Date(`${defect.date}T${defect.time}`);

  // Find relevant drum data
  const relevantDrum = drumsData.find((drum) => {
    const drumStartTime = new Date(`${defect.date}T${drum.start_time}`);
    const drumEndTime = drum.end_time
      ? new Date(`${defect.date}T${drum.end_time}`)
      : null;

    if (drumEndTime) {
      return (
        drum.machine_name === defect.rst &&
        defectDateTime >= drumStartTime &&
        defectDateTime <= drumEndTime
      );
    } else {
      return (
        drum.machine_name === defect.rst && defectDateTime >= drumStartTime
      );
    }
  });

  if (relevantDrum) {
    defect.drum = relevantDrum.drum_number.replace(/_+$/,"");
  }
});


    console.log("tableData", tableData);

    const sortedTableData = tableData.slice().sort((a, b) => {
      const getTimeInSeconds = (time) => {
        const parts = time.split(":").map(Number);
        let seconds = 0;
        for (let i = 0; i < parts.length; i++) {
          seconds += parts[i] * Math.pow(60, parts.length - 1 - i);
        }
        return seconds;
      };
      let timeA;
      let timeB;
      timeA = getTimeInSeconds(a.time);
      timeB = getTimeInSeconds(b.time);

      return timeB - timeA;
    });

    let filteredEntries = sortedTableData.filter(
      (entry) =>
        entry.time !== null &&
        entry.time !== "" 
    );

    // Apply filtering based on selectedRST
    if (selectedRST) {
      filteredEntries = filteredEntries.filter(
        (entry) => entry.rst === selectedRST
      );
    }

    if (selectedCage) {
      filteredEntries = filteredEntries.filter(
        (entry) => entry.cage === selectedCage
      );
    }
    if (selectedDefect) {
      filteredEntries = filteredEntries.filter(
        (entry) =>
          entry.defectType ===
          selectedDefect
      );
    }
    if (selectedMachineProcess !== null) {
      filteredEntries = filteredEntries.filter(
        (entry) =>
          entry.machineStatus ===
          selectedMachineProcess
      );
    }
    if (selectedAccuracy !==null) {
      filteredEntries = filteredEntries.filter((entry) => {
        const accuracy = parseFloat(entry.defectAccuracy);
        return (     
          (!selectedAccuracy || 
            (Number(selectedAccuracy) === 100 
              ? accuracy === 100.00 
              : accuracy >= Number(selectedAccuracy) && accuracy <= Number(selectedAccuracy) + 0.99)
          ) 
        );
      })
    }

    if (selectedShift) {
      const [shiftStart, shiftEnd] = selectedShift.split("-").map((time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      });

      filteredEntries = filteredEntries.filter((entry) => {
        const entryTime = entry.time.split(":").map(Number);
        const entryTimeInMinutes = entryTime[0] * 60 + entryTime[1];

        if (shiftStart <= shiftEnd) {
          return (
            entryTimeInMinutes >= shiftStart && entryTimeInMinutes <= shiftEnd
          );
        } else {
          return (
            entryTimeInMinutes >= shiftStart || entryTimeInMinutes <= shiftEnd
          );
        }
      });
    }

    filteredEntries.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Update the filtered entries state
    setFilteredEntries(filteredEntries);
  }, [tableData, selectedRST, selectedDate, selectedShift, selectedCage, selectedMachineProcess, selectedDefect,selectedAccuracy, drumsData]);


  // const handleSelectDateChange = (event) => {
  //   const selectedDateOption = event.target.value;
  //   // Logic to handle the selected date option
  // };

  const addDefectColor = (defect) => {
    const lowerCaseDefectType = defect? defect?.toLowerCase() : "";
    // Special case for blackwire variations
    if (lowerCaseDefectType.startsWith("blackwire")) {
      return {
        defectType: "Blackwire",
        color: defects?.blackwire?.color,
        bgColor: defects?.blackwire?.bgColor,

      };
    }
    if (lowerCaseDefectType.startsWith("entangled")) {
      return {
        defectType: "Entangled",
        color: defects?.entangled?.color,
        bgColor: defects?.entangled?.bgColor,

      };
    }

    if (defects.hasOwnProperty(lowerCaseDefectType)) {
      return {
        defectType:lowerCaseDefectType,
        color: defects[lowerCaseDefectType]?.color,
        bgColor: defects[lowerCaseDefectType]?.bgColor,

      };
    } else if (!defects.hasOwnProperty(lowerCaseDefectType)) {
      return {
        defectType: lowerCaseDefectType ? lowerCaseDefectType : "Undetermined",
        color: "#000000",
        bgColor: "#FFFFFF",

      };
    } else {
      return {
        defectType: "Undetermined",
        color: "#000000",
        bgColor: "#FFFFFF",
        status: null,
        percentage: null,
      };
    }
  };

  const handleCalenderToggle = () => {
    setCalenderOpen(!calenderOpen);
  };

  const toggleYesPopup = (imageUrl) => {
    setShowYesPopup(!showYesPopup);
    setCurrentImgUrl(imageUrl);
    setShowNoPopup(false); // Close the other popup
  };

  const toggleNoPopup = (imageUrl) => {
    setShowNoPopup(!showNoPopup);
    setCurrentImgUrl(imageUrl);
    setShowYesPopup(false); // Close the other popup
  };

  const handleVerifyAndRename = async (defectType) => {
    try {
      // Remove spaces and convert to lowercase
      const formattedDefectType = defectType.replace(/\s+/g, "").toLowerCase();

      // Make API request to verify and rename the image
      const response = await fetch(`${BASE_URL}/verifyAndRenameImage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          imageUrl: currentImgUrl,
          defectType: formattedDefectType,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Refresh the data after successful verification and renaming
      if (selectedDate) {
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const indianTime = new Date(selectedDate.getTime() + indianTimeOffset);
        const formattedDate = indianTime.toISOString().split("T")[0]; // Use indianTime for formatting
        console.log(indianTime);
        // Format the date as 'YYYY-MM-DD'
        fetchData(formattedDate);
      } else {
        fetchData(); // If no date is selected, fetch data for today's date
      }

      // Close the verify popup
      setShowYesPopup(false);
    } catch (error) {
      console.error("Error verifying and renaming image:", error);
      // Handle error as needed
    }
  };
  console.log(defects);
  const handleDeleteImage = async () => {
    // Call the API to delete the image
    try {
      const response = await fetch(`${BASE_URL}/moveAndDeleteImage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          imageUrl: currentImgUrl,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Refresh the data after successful deletion
      if (selectedDate) {
        const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
        const indianTime = new Date(selectedDate.getTime() + indianTimeOffset);
        const formattedDate = indianTime.toISOString().split("T")[0]; // Use indianTime for formatting
        console.log(indianTime);
        // Format the date as 'YYYY-MM-DD'
        fetchData(formattedDate);
      } else {
        fetchData(); // If no date is selected, fetch data for today's date
      }
      // Close the delete confirmation popup
      setShowNoPopup(false);
    } catch (error) {
      console.error("Error deleting image:", error);
      // Handle error as needed
    }
  };

  useEffect(() => {
    const newUniqueDefects = new Set();
    if (tableData.length > 0) {
    }
    tableData
      .filter((row) => row.rst === selectedRST)
      .forEach((row) => {
        if (row.defectType !=="" || row.defectType !=="Undetermined") {
          const defectTypeInfo = row.defectType;
          if (defectTypeInfo) {
            newUniqueDefects.add(defectTypeInfo);
          }
        }
      });

    setUniqueDefectsArr(Array.from(newUniqueDefects));
  }, [selectedRST, tableData]);
  const formatEntriesDate = (dateString) => {
    const [year, month, day] = dateString.split("/");
    if (year === "undefined" || month === "undefined" || day === "undefined") {
      return "Invalid date format";
    }
    return `${day}/${month}/${year}`;
  };

  const formatEntriesDates = (dateString) => {
    if (dateString.includes("undefined")) {
      return "Invalid date format";
    }
    const [year, month, day] = dateString.split("-");
    if (!year || !month || !day) {
      return "Invalid date format";
    }
    return `${day}/${month}/${year}`;
  };

  const disableFutureDates = ({ date, view }) => {
    // Disable future dates
    if (view === "month") {
      return date > new Date();
    }
  };
  console.log("uni", uniqueDefectsArr);
  const handleMinimizeImage = () => {
    setIsImageOpen(false);
  };
  const handleMaximizeImage = (image) => {
    setSelectedImage(image);
    setIsImageOpen(true);
  };
  const handlePdfDownload = async () => {
    setDownloadOpen(false);
   const formattedDate=formatDate(selectedDate);

    // Construct the URL with query parameters
    const downloadUrl = `${window.location.origin}/download-pdf?date=${formattedDate}&rst=${selectedRST}&selectedShift=${selectedShift}&selectedAccuracy=${selectedAccuracy}`;

    // Open the download page in a new tab
    window.open(downloadUrl, '_blank');
};
  const handleDownloadToggle = () => {
      setDownloadOpen(!downloadOpen);
  }; 
  const getDefaultImageUrl = (images) => {
    const defaultString = 'default';
    if (images.length === 1) {
      // If there is only one image, return its URL
      return images[0].url;
    }
  
    // If there are multiple images, find the one with camNo including defaultString
    const image = images?.find(img => img.camNo.includes(defaultString));
    return image ? image.url : images[0]?.url;
  };
  const getDefaultBase64ImageUrl = (images) => {
    const defaultString = 'default';
    if (images.length === 1) {
      // If there is only one image, return its URL
      return images[0].url;
    }
  
    // If there are multiple images, find the one with camNo including defaultString
    const image = images.find(img => img.camNo.includes(defaultString));
    return image ? image.url : images[0].url;
  };
  return (
    <div className="report-main-container">
          <DownloadPopup
        showDownloadPopup={showDownloadPopup}
        toggleDownloadPopup={toggleDownloadPopup}
        selectedDate={selectedDate}
      />
      <VerifyPopup
        showYesPopup={showYesPopup}
        toggleYesPopup={toggleYesPopup}
        handleVerifyAndRename={handleVerifyAndRename}
      />
      <DeletePopup
        showNoPopup={showNoPopup}
        toggleNoPopup={toggleNoPopup}
        handleDelete={handleDeleteImage}
      />
      <div className="report-container">
        {calenderOpen && (
          <div className="calender-container">
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              tileDisabled={disableFutureDates}
            />
          </div>
        )}
        <div className="report-dropdown-flex-container">
          <div className="machine-status-button-container">
            <button
              onClick={handleMachineStop}
              className={`machine-stop-button ${
                selectedMachineProcess === "STOP" ? "active" : ""
              }`}
            >
              Stopped
            </button>
            <button
              onClick={handleMachineStart}
              className={`machine-start-button  ${
                selectedMachineProcess === "START" ? "active" : ""
              }`}
            >
              Not Stopped
            </button>
          </div>
          <div className="report-dropdown-flex">
            <select
              key="Defect"
              id="defectDropdown"
              className="report-dropdown"
              onChange={handleSelectedDefectChange}
            >
              <option
                className="report-dropdown-option"
                value=""
                // disabled={tableData.length === 0}
              >
                All Defects
              </option>
              {uniqueDefectsArr &&
                uniqueDefectsArr.length > 0 &&
                uniqueDefectsArr.map((defect) => (
                  <option className="report-dropdown-option" value={defect}>
                    {defect}
                  </option>
                ))}
            </select>
            <div className="defect-dropdown-contatiner">
                    <select
                      name="defects"
                      id="defect-dropdown"
                      className="reportt-dropdown"
                      onChange={(e) => setSelectedAccuracy(e.target.value)}
                    >
                      <option value="">All Accuracies</option>
                      <option value="100">100</option>
                      <option value="99">99</option>
                      <option value="98">98</option>
                      <option value="97">97</option>
                      <option value="96">96</option>
                      <option value="95">95</option>
                      <option value="94">94</option>
                      <option value="93">93</option>
                    </select>
                  </div>
            <select
              key="Cage"
              id="cageDropdown"
              className="report-dropdown"
              onChange={handleCageChange}
            >
              <option className="report-dropdown-option" value="">
                All Cages
              </option>
              <option className="report-dropdown-option" value="Cage12">
                Cage 12
              </option>
              <option className="report-dropdown-option" value="Cage18">
                Cage 18
              </option>
              <option className="report-dropdown-option" value="Cage24">
                Cage 24
              </option>
            </select>
            <select
              key="Shift"
              id="ShiftDropdown"
              className="report-dropdown"
              onChange={handleShiftChange}
            >
              <option className="report-dropdown-option" value="">
                All Shifts
              </option>
              <option className="report-dropdown-option" value="07:00-15:00">
                Shift 1
              </option>
              <option className="report-dropdown-option" value="15:00-23:00">
                Shift 2
              </option>
              <option className="report-dropdown-option" value="23:00-07:00">
                Shift 3
              </option>
            </select>

            <button
              className="date-button"
              disabled={tableDataLoading}
              onClick={handleCalenderToggle}
            >
              {formatEntriesDate(selectedDateString)}{" "}
              <SlArrowDown className="down-arrow-icon" />
            </button>
            <div className="download-whole-container">
              <button
                className="report-dropdown download-button"
                // disabled={
                //     filteredEntries.length===0
                // }
                onClick={handleDownloadToggle}
              >
                Download
              </button>
              {downloadOpen && (
                <div className="download-container report">
                  <button
                    className="download-button-in"
                    onClick={toggleDownloadPopup}
                  >
                    Excel
                  </button>
                  <button
                    className="download-button-in"
                    onClick={handlePdfDownload}
                  >
                    PDF
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Paper className="table-paper">
          <div style={{ overflow: "auto" }}>
            {filteredEntries.length === 0 && tableDataLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 190px)",
                  padding: "15rem",
                  backgroundColor: "rgb(246, 248, 250)",
                }}
              >
                <CircularProgress />
              </div>
            ) : filteredEntries.length === 0 ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <NoRecords />
              </div>
            ) : (
              <TableContainer className="table-height">
                {filteredEntries.length > 0 ? (
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            className="TableHeaderCell"
                            key={column.id}
                            align={column.align}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredEntries.map(
                        (row, index) =>
                          row.date && (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell
                                className="report-table-row"
                                align="center"
                              >
                                {formatEntriesDates(row.date)}
                              </TableCell>
                              <TableCell
                                className="report-table-row"
                                align="center"
                              >
                                {row.time}
                              </TableCell>
                              <TableCell
                                className="report-table-row"
                                align="center"
                              >
                                {row.cage}
                              </TableCell>
                              <TableCell
                                className="report-table-row"
                                align="center"
                                justifyContent="center"
                              >
                                <div className="defect-type-cell-container">
                                  <div
                                    className="defect-type-cell"
                                    style={{
                                      backgroundColor: addDefectColor(
                                        row.defectType
                                      ).bgColor,
                                      color: addDefectColor(row.defectType)
                                        .color,
                                      padding: "0.25rem",
                                      borderRadius: "0.25rem",
                                    }}
                                  >
                                    {row.defectType}
                                    <span>
                                      {row.defectAccuracy
                                        ? `${row.defectAccuracy} %`
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              {/* <TableCell
                              className="report-table-row"
                              align="center"
                            >
                              <div className="yes-no-btn-container">
                                <button
                                  className="yes-btn yes-no-btn"
                                  onClick={() => toggleYesPopup(row.url)}
                                >
                                  <FaCheck className="btn-icon" />
                                </button>
                                <button
                                  className="no-btn yes-no-btn"
                                  onClick={() => toggleNoPopup(row.url)}
                                >
                                  <FaTimes className="btn-icon" />
                                </button>
                              </div>
                            </TableCell> */}
                              <TableCell
                                className="report-table-row"
                                align="center"
                              >
                                {row.drum ? row.drum : "-"}
                              </TableCell>
                              <TableCell
                                className="report-table-row"
                                align="center"
                              >
                                {isImageOpen &&
                                selectedImage ===
                                  getDefaultImageUrl(row.images) ? (
                                  <div className="diglogBox-bg">
                                    <div className="modal-overlay">
                                      <div className="modal-content">
                                        {/* Close Button */}
                                        <div className="closer">
                                          <button
                                            className="minimize-button"
                                            onClick={handleMinimizeImage}
                                          >
                                            &times;
                                          </button>
                                        </div>

                                        {/* Full-size Image */}
                                        {/* <img
                                          src={selectedImage}
                                          alt="Full"
                                          className="full-big-image"
                                        /> */}

                                        {/* Thumbnails and camNo */}
                                        <div className="thumbnail-container">
                                          {row.images
                                            .slice() // Create a copy of the array to avoid mutating the original
                                            .sort((a, b) => {
                                              // Sort images to prioritize those with 'default' in camNo
                                              const aHasDefault =
                                                a.camNo.includes("default");
                                              const bHasDefault =
                                                b.camNo.includes("default");

                                              if (aHasDefault && !bHasDefault)
                                                return -1;
                                              if (!aHasDefault && bHasDefault)
                                                return 1;

                                              return 0; // Maintain original order if both or neither have 'default'
                                            })
                                            .map((image) => (
                                              <div
                                                key={image.url}
                                                className="image-item"
                                              >
                                                <img
                                                  src={image.url}
                                                  alt="Thumbnail"
                                                  className="full-big-image"
                                                />
                                                <div className="cam-no">
                                                  {image.camNo.includes("-")
                                                    ? `${
                                                        image.camNo.split(
                                                          "-"
                                                        )[0]
                                                      } ${
                                                        image.camNo.split(
                                                          "-"
                                                        )[1]
                                                      }`
                                                    : image.camNo}
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="centered-image">
                                    <img
                                      src={getDefaultImageUrl(row.images)}
                                      alt="Full"
                                      className="full-image"
                                      loading="lazy"
                                    />
                                    {row.machineStatus === "STOP" && (
                                      <div className="machine-stop-icon-container">
                                        <img
                                          className="machine-stop-icon"
                                          src={machineStoppedIcon}
                                          width={32}
                                          height={32}
                                        />
                                      </div>
                                    )}
                                    <button
                                      className="maximize-button"
                                      onClick={() =>
                                        handleMaximizeImage(
                                          getDefaultImageUrl(row.images)
                                        )
                                      }
                                    >
                                      <LuMaximize2 />
                                    </button>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  <NoRecords />
                )}
              </TableContainer>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  drumsData: state.drums.data,

  tableData: state.data.data,
  tableDataLoading: state.data.loading,
  defects: state.defectsData.data,
});

const mapDispatchToProps = {
  fetchData,
  fetchDefectData,
  fetchDrumsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
