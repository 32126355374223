import axios from "axios";
import { BASE_URL, BASE_URL_USER } from "../baseurl";
import * as XLSX from "xlsx";
export const fetchDataRequest = () => ({
  type: "FETCH_DATA_REQUEST",
});

const fetchDataSuccess = (data) => ({
  type: "FETCH_DATA_SUCCESS",
  payload: data,
});

const fetchDataFailure = (error) => ({
  type: "FETCH_DATA_FAILURE",
  payload: error,
});

export const fetchData = (date) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    let url = `${BASE_URL}/getS3Objects`;
    if (date) {
      url = `${url}?date=${date}`;
    }

    return axios
      .get(url)
      .then((res) => {
        dispatch(fetchDataSuccess(res.data));
        return res.data; // Return data for potential chaining
      })
      .catch((error) => {
        dispatch(fetchDataFailure(error));
        // throw error; // Re-throw the error for further handling
        console.log(error);
      });
  };
};

export const fetchRstRequest = () => ({
  type: "FETCH_RST_REQUEST",
});

const fetchRstSuccess = (data) => ({
  type: "FETCH_RST_SUCCESS",
  payload: data,
});

const fetchRstFailure = (error) => ({
  type: "FETCH_RST_FAILURE",
  payload: error,
});
export const fetchRST = () => {
  // Modify fetchData to accept a date parameter
  return (dispatch) => {
    dispatch(fetchRstRequest());
    const url = `${BASE_URL}/getRsts`;
    axios
      .get(url)
      .then((res) => {
        dispatch(fetchRstSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchRstFailure(error));
      });
  };
};

export const fetchBase64ImgDataRequest = () => ({
  type: "FETCH_BASE64IMG_DATA_REQUEST",
});

const fetchBase64ImgDataSuccess = (data) => ({
  type: "FETCH_BASE64IMG_DATA_SUCCESS",
  payload: data,
});

const fetchBase64ImgDataFailure = (error) => ({
  type: "FETCH_BASE64IMG_DATA_FAILURE",
  payload: error,
});

export const fetchBase64ImgData = (date) => {
  // Modify fetchData to accept a date parameter
  return (dispatch) => {
    dispatch(fetchBase64ImgDataRequest());
    const url = date
      ? `${BASE_URL}/getS3ObjectsWithBase64?date=${date}`
      : `${BASE_URL}/getS3ObjectsWithBase64`;
    axios
      .get(url)
      .then((res) => {
        dispatch(fetchBase64ImgDataSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchBase64ImgDataFailure(error));
      });
  };
};

const fetchJsonDataRequest = () => ({
  type: "FETCH_JSON_DATA_REQUEST",
});

const fetchJsonDataSuccess = (data) => ({
  type: "FETCH_JSON_DATA_SUCCESS",
  payload: data,
});

const fetchJsonDataFailure = (error) => ({
  type: "FETCH_JSON_DATA_FAILURE",
  payload: error,
});

export const fetchJsonData = () => {
  return (dispatch) => {
    dispatch(fetchJsonDataRequest());
    axios
      .get(`${BASE_URL}/getS3json`)
      .then((res) => {
        dispatch(fetchJsonDataSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchJsonDataFailure(error));
      });
  };
};

export const fetchAnalyticsDataRequest = () => ({
  type: "FETCH_ANALYTICS_DATA_REQUEST",
});

export const fetchAnalyticsDataSuccess = (data) => ({
  type: "FETCH_ANALYTICS_DATA_SUCCESS",
  payload: data,
});

const fetchAnalyticsDataFailure = (error) => ({
  type: "FETCH_ANALYTICS_DATA_FAILURE",
  payload: error,
});

export const fetchAnalyticsData = (date) => {
  return (dispatch) => {
    dispatch(fetchAnalyticsDataRequest());
    axios
      .get(`${BASE_URL}/getAnalyticsData?date=${date}`)
      .then((res) => {
        dispatch(fetchAnalyticsDataSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchAnalyticsDataFailure(error));
      });
  };
};

export const fetchNocableDataRequest = () => ({
  type: "FETCH_NOCABLE_DATA_REQUEST",
});

const fetchNocableDataSuccess = (data) => ({
  type: "FETCH_NOCABLE_DATA_SUCCESS",
  payload: data,
});

const fetchNocableDataFailure = (error) => ({
  type: "FETCH_NOCABLE_DATA_FAILURE",
  payload: error,
});

export const fetchNocableData = (date) => {
  return (dispatch) => {
    dispatch(fetchNocableDataRequest());
    axios
      .get(`${BASE_URL}/getNocablesData?date=${date}`)
      .then((res) => {
        dispatch(fetchNocableDataSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchNocableDataFailure(error));
      });
  };
};

export const loginRequest = () => ({
  type: "LOGIN_REQUEST",
});

export const loginSuccess = (data) => ({
  type: "LOGIN_SUCCESS",
  payload: { ...data, isAuthenticated: true, success: true }, // Include isAuthenticated in payload
});

export const loginFailure = (error) => ({
  type: "LOGIN_FAILURE",
  payload: { error, isAuthenticated: false, success: false }, // Include isAuthenticated in payload
});

export const fetchLogin = (username, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const response = await fetch(`${BASE_URL_USER}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to login"); // Handle non-200 status codes
    }

    const data = await response.json();
    console.log(data);
    dispatch(loginSuccess(data));
    return true;
  } catch (error) {
    console.log(error);
    dispatch(loginFailure(error.message));
    return false;
  }
};
export const logoutRequest = () => ({
  type: "LOGOUT_REQUEST",
});

export const logoutSuccess = (data) => ({
  type: "LOGOUT_SUCCESS",
  payload: { ...data, isAuthenticated: false }, // Include isAuthenticated in payload
});

export const logoutFailure = (error) => ({
  type: "LOGOUT_FAILURE",
  payload: { error, isAuthenticated: false }, // Include isAuthenticated in payload
});
export const fetchLogout = () => async (dispatch) => {
  try {
    dispatch(logoutRequest());
    const response = await fetch(`${BASE_URL_USER}/user/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to logout"); // Handle non-200 status codes
    }

    const data = await response.json();
    console.log(data);
    dispatch(logoutSuccess(data));
    return true;
  } catch (error) {
    console.log(error);
    dispatch(logoutFailure(error.message));
    return false;
  }
};
export const fetchDefectData = () => async (dispatch) => {
  try {
    dispatch({ type: "DEFECT_FETCH_REQUEST" });

    const response = await fetch(`${BASE_URL}/defects`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch report"); // Handle non-200 status codes
    }

    const data = await response.json();
    const defectsData = data.defects;
    console.log(defectsData);
    dispatch({ type: "DEFECT_FETCH_SUCCESS", payload: defectsData });

    return true;
  } catch (error) {
    console.log(error);
    dispatch({ type: "DEFECT_FETCH_FAILURE", payload: error.message });
    return false;
  }
};
export const updateDefect = (defect) => async (dispatch) => {
  try {
    dispatch({ type: "DEFECT_UPDATE_REQUEST" });

    const response = await fetch(`${BASE_URL}/add-defect`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newDefect: defect }),
    });

    if (!response.ok) {
      throw new Error("Failed to edit defect"); // Handle non-200 status codes
    }

    const data = await response.json();
    console.log(data);
    dispatch({ type: "DEFECT_UPDATE_SUCCESS", payload: data });

    return true;
  } catch (error) {
    console.log(error);
    dispatch({ type: "DEFECT_UPDATE_FAILURE", payload: error.message });

    return false;
  }
};

//-------------------------------------DRUMS---------------------------------------------

export const drumsAddRequest = () => ({
  type: "DRUMS_ADD_REQUEST",
});

export const drumsAddSuccess = (data) => ({
  type: "DRUMS_ADD_SUCCESS",
  payload: { ...data, success: true },
});

export const drumsAddFailure = (error) => ({
  type: "DRUMS_ADD_FAILURE",
  payload: { error, success: false },
});

export const addDrums =
  (machineName, drumNo,date, startTime, endTime, drumLength) => async (dispatch) => {
    try {
      dispatch(drumsAddRequest());
      const response = await fetch(`${BASE_URL_USER}/drums/add-drum`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          machineName: machineName,
          drumNo: drumNo,
          date:date,
          startTime: startTime,
          endTime: endTime,
          drumLength: drumLength,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add drum"); // Handle non-200 status codes
      }

      const data = await response.json();
      console.log(data);
      dispatch(drumsAddSuccess(data));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(drumsAddFailure(error.message));
      return false;
    }
  };

export const drumsFetchRequest = () => ({
  type: "FETCH_DRUMS_REQUEST",
});

const drumsFetchSuccess = (data) => ({
  type: "FETCH_DRUMS_SUCCESS",
  payload: data,
});

const drumsFetchFailure = (error) => ({
  type: "FETCH_DRUMS_FAILURE",
  payload: error,
});
// export const downloadExcelFile = async (date) => {
//   try {
//     const response = await fetch(`${BASE_URL}/getS3ObjectsExcel?date=${date}`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//       }

//     });

//     const data = await response.blob();
//   console.log(data)
//     // Convert the blob to a file
//     const excelFile = new File([data], 'defect_data.xls', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

//     // Create a URL for the file
//     const url = URL.createObjectURL(excelFile);

//     // Initiate download
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', 'defect_data.xls');
//     document.body.appendChild(link);
//     link.click();

//     // Clean up
//     URL.revokeObjectURL(url);
//     link.remove();
//   } catch (error) {
//     console.error('Error downloading Excel file:', error);
//   }
// };
export const downloadExcelFile = async (fromDate, toDate,rst) => {
    try {
      const response = await fetch(
        `${BASE_URL}/getS3ObjectsExcel?fromDate=${fromDate}&toDate=${toDate}&selectedRst=${rst}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      // Convert the response to a blob
      const data = await response.blob();
  
      // Create a URL for the blob
      const url = URL.createObjectURL(data);
  
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'defects_data.xlsx');
      document.body.appendChild(link);
      link.click();
  
      // Clean up by revoking the object URL and removing the temporary element
      URL.revokeObjectURL(url);
      // link.remove();
    } catch (error) {
      console.error('Error downloading Excel file:', error);
      // Optional: You can show an alert or some UI indication of the error
    }
  };
export const fetchDrumsData = (date) => {
  return (dispatch) => {
    dispatch(drumsFetchRequest());

    let url = `${BASE_URL_USER}/drums/get-drums`;
    if (date) {
      url = `${url}?date=${date}`;
    }

    return axios
      .get(url)
      .then((res) => {
        dispatch(drumsFetchSuccess(res.data));
        return res.data; // Return data for potential chaining
      })
      .catch((error) => {
        dispatch(drumsFetchFailure(error));
        // throw error; // Re-throw the error for further handling
        console.log(error);
      });
  };
};

export const EDIT_DRUM_REQUEST = "EDIT_DRUM_REQUEST";
export const EDIT_DRUM_SUCCESS = "EDIT_DRUM_SUCCESS";
export const EDIT_DRUM_FAILURE = "EDIT_DRUM_FAILURE";

export const DELETE_DRUM_REQUEST = "DELETE_DRUM_REQUEST";
export const DELETE_DRUM_SUCCESS = "DELETE_DRUM_SUCCESS";
export const DELETE_DRUM_FAILURE = "DELETE_DRUM_FAILURE";

export const editDrumRequest = () => ({
  type: EDIT_DRUM_REQUEST,
});

export const editDrumSuccess = (data) => ({
  type: EDIT_DRUM_SUCCESS,
  payload: data,
});

export const editDrumFailure = (error) => ({
  type: EDIT_DRUM_FAILURE,
  payload: error,
});

export const deleteDrumRequest = () => ({
  type: DELETE_DRUM_REQUEST,
});

export const deleteDrumSuccess = (id) => ({
  type: DELETE_DRUM_SUCCESS,
  payload: id,
});

export const deleteDrumFailure = (error) => ({
  type: DELETE_DRUM_FAILURE,
  payload: error,
});

export const editDrum = (id, drumData) => async (dispatch) => {
  dispatch(editDrumRequest());
  try {
    const response = await axios.put(
      `${BASE_URL_USER}/drums/edit-drum/?id=${id}`,
      drumData
    );
    dispatch(editDrumSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(editDrumFailure(error.response.data));
    throw error; // Ensure error is thrown to handle it properly
  }
};

export const deleteDrum = (id) => async (dispatch) => {
  dispatch(deleteDrumRequest());
  try {
    await axios.delete(`${BASE_URL_USER}/drums/delete-drum/?id=${id}`);
    dispatch(deleteDrumSuccess(id));
  } catch (error) {
    dispatch(deleteDrumFailure(error.response.data));
  }
};

// actionTypes.js
export const FETCH_DRUM_REQUEST = "FETCH_DRUM_REQUEST";
export const FETCH_DRUM_SUCCESS = "FETCH_DRUM_SUCCESS";
export const FETCH_DRUM_FAILURE = "FETCH_DRUM_FAILURE";

export const fetchDrumRequest = () => ({
  type: FETCH_DRUM_REQUEST,
});

export const fetchDrumSuccess = (data) => ({
  type: FETCH_DRUM_SUCCESS,
  payload: data,
});

export const fetchDrumFailure = (error) => ({
  type: FETCH_DRUM_FAILURE,
  payload: error,
});

export const fetchDrum = (id) => async (dispatch) => {
  dispatch(fetchDrumRequest());
  try {
    const response = await axios.get(
      `${BASE_URL_USER}/drums/drum-details/?id=${id}`
    );
    dispatch(fetchDrumSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchDrumFailure(error.response.data));
  }
};
