import React, { useEffect, useCallback, useState } from "react";

import notification from "../../images/notification.svg";
import user from "../../images/user.svg";
import sterlitelogo from "../../images/sterlitelogo.png";
import "./Navbar.css";

const Navbar = ({ setSelectedRST }) => {
  const [activeRST, setActiveRST] = useState(null);
  const rstArray = ["RST5", "RST6"];
  const [hasClickedRST, setHasClickedRST] = useState(false);

  const handleRSTClick = useCallback(
    (rstKey) => {
      setSelectedRST(rstKey);
      setActiveRST(rstKey);
      setHasClickedRST(true);
    },
    [setSelectedRST]
  );

  const isActive = (rstKey) => activeRST === rstKey;

  useEffect(() => {
    if (!hasClickedRST && rstArray.length > 0) {
      const defaultRSTKey = rstArray[0];
      handleRSTClick(defaultRSTKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rstArray, hasClickedRST]);

  return (
    <div className="report-navbar">
      <div className="report-navbar-content">
        <div>
          <span className="report-header">Report</span>
        </div>
        <div className="analytics-navbar-buttons">
          <button className="nav-button">
            <img
              src={notification}
              alt="Notification"
              className="nav-icon"
              width={20}
              height={20}
            />
          </button>
          <button className="nav-button">
            <img
              src={user}
              alt="User"
              className="nav-icon"
              width={17}
              height={17}
            />
          </button>
          <button className="nav-button">
            <img
              src={sterlitelogo}
              alt="Sterlite Logo"
              className="nav-icon"
              width={"50px"}
            />
          </button>
        </div>
      </div>
      <div className="rst">
        {rstArray.map((rstKey) => (
          <span
            key={rstKey}
            className={`rst-link ${isActive(rstKey) ? "active" : ""}`}
            onClick={() => handleRSTClick(rstKey)}
          >
            <b>{rstKey}</b>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Navbar;

// import React, { useEffect, useCallback, useState } from "react";
// import { connect } from "react-redux";
// import {fetchRST } from "../../redux/actions";
// import notification from "../../images/notification.svg";
// import user from "../../images/user.svg";
// import sterlitelogo from '../../images/sterlitelogo.png'
// import "./Navbar.css"; // Make sure to import your CSS file

// const Navbar = ({fetchRST , setSelectedRST, data }) => {
//   const [activeRST, setActiveRST] = useState(null);
//   const [rstArray, setRstArray] = useState([]);
//   const [hasClickedRST, setHasClickedRST] = useState(false);

//   useEffect(() => {
//     fetchRST();
//   }, [fetchRST]);

//   const handleRSTClick = useCallback(
//     (rstKey) => {
//       setSelectedRST(rstKey);
//       setActiveRST(rstKey);
//       setHasClickedRST(true);
//     },
//     [setSelectedRST]
//   );

//   const isActive = (rstKey) => activeRST === rstKey;

//   useEffect(() => {
//     setRstArray(data);
//   }, [data]);

//   useEffect(() => {
//     if (!hasClickedRST && rstArray.length > 0) {
//       const defaultRSTKey = rstArray[0];
//       handleRSTClick(defaultRSTKey);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [rstArray, hasClickedRST]);

//   return (
//     <div className="report-navbar">
//       <div className="report-navbar-content">
//         <div>
//           <span className="report-header">
//             Report
//           </span>
//         </div>
//         <div className="analytics-navbar-buttons">
//         <button className="nav-button">
//   <img src={notification} alt="Notification" className="nav-icon" width={20} height={20}/>
// </button>
// <button className="nav-button">
//   <img src={user} alt="User" className="nav-icon"width={17} height={17}/>
// </button>
// <button className="nav-button">
//   <img src={sterlitelogo} alt="Sterlite Logo" className="nav-icon" width={"50px"} />
// </button>
//         </div>
//       </div>
//       <div className="rst">
//         {rstArray.map((rstKey) => (
//           <span
//             key={rstKey}
//             className={`rst-link ${isActive(rstKey) ? "active" : ""}`}
//             onClick={() => handleRSTClick(rstKey)}
//           >
//             <b>{rstKey}</b>
//           </span>
//         ))}
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   data: state.rstData.data,

// });

// const mapDispatchToProps = {
//   fetchRST
// };
// export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
