/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./analyticsMain.css";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import noData from "../../images/no-records-found.png";
import CircularProgress from "@mui/material/CircularProgress";
import BarChart from "./BarChart";

import {
  fetchAnalyticsData,
  fetchData,
  fetchDataRequest,
  fetchAnalyticsDataRequest,
  fetchNocableData,
  fetchAnalyticsDataSuccess,
} from "../../redux/actions";
import { Chart } from "react-google-charts";
import { SlArrowDown } from "react-icons/sl";
import Calendar from "react-calendar";
import "reactjs-popup/dist/index.css";
import "react-calendar/dist/Calendar.css";
const { DateTime } = require("luxon");
const Main = ({
  fetchAnalyticsData,
  apiData,
  selectedRST,
  objectData,
  fetchData,
  fetchDataRequest,
  fetchAnalyticsDataRequest,
  fetchAnalyticsDataSuccess,
  fetchNocableData,
  nocableData,
  apiDataLoading,
  objectDataLoading,
  nocableDataLoading,
}) => {
  const [dynamicStartTime, setDynamicStartTime] = useState("");
  const [dynamicEndTime, setDynamicEndTime] = useState("");
  const [timelineLabels, setTimelineLabels] = useState([]);
  const [shift, setShift] = useState();
  const [refresh, setRefresh] = useState(new Date());
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [selectedDateString, setSelectedDateString] = useState("");

  const [selectedDate, setSelectedDate] = useState(DateTime.local());

  const [hoveredEvent, setHoveredEvent] = useState("");
  const [hoveredCage24Event, setHoveredCage24Event] = useState("");
  const dropdownOptions = [
    {
      shift: "Shift 1",
      starttime: "07:00:00",
      endtime: "15:00:00",
      time: [
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
      ],
    },
    {
      shift: "Shift 2",
      starttime: "15:00:00",
      endtime: "23:00:00",
      time: [
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    {
      shift: "Shift 3",
      starttime: "23:00:00",
      endtime: "07:00:00",
      time: [
        "23:00",
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
      ],
    },
  ];
  const [selectedShift, setSelectedShift] = useState("shift1");
  const shifts = {
    shift1: { starttime: "07:00", endtime: "15:00" },
    shift2: { starttime: "15:00", endtime: "23:00" },
    shift3: { starttime: "23:00", endtime: "07:00" },
  };
  const { starttime, endtime } = shifts[selectedShift];

  const getCurrentShift = () => {
    const now = new Date();
    const currentTime = now.toTimeString().slice(0, 5); // Format HH:MM

    // Function to check if a time is within a shift's range
    const isTimeInShift = (start, end, time) => {
      if (start < end) {
        return time >= start && time < end;
      } else {
        return time >= start || time < end;
      }
    };

    // Check each shift
    for (const [shiftName, { starttime, endtime }] of Object.entries(shifts)) {
      if (isTimeInShift(starttime, endtime, currentTime)) {
        return shiftName;
      }
    }

    return null;
  };

  useEffect(() => {
    // Determine the current shift
    const shift = getCurrentShift();
    setSelectedShift(shift);
  }, []);
  useEffect(() => {
    const now = DateTime.local();
    if (now.hour < 7) {
      setSelectedDate(now.minus({ days: 1 }));
    } else {
      setSelectedDate(now);
    }
  }, []);
  const isCurrentShift = (shiftOption) => {
    const currentTime = DateTime.local();
    const { starttime, endtime } = shiftOption;

    if (starttime <= endtime) {
      // No midnight crossing
      return (
        currentTime >= DateTime.fromFormat(starttime, "HH:mm:ss") &&
        currentTime < DateTime.fromFormat(endtime, "HH:mm:ss")
      );
    } else {
      // Midnight crossing, check both intervals
      const endDateTime = DateTime.fromFormat(endtime, "HH:mm:ss");
      return (
        currentTime >= DateTime.fromFormat(starttime, "HH:mm:ss") ||
        currentTime < endDateTime.plus({ days: 1 })
      ); // Add one day to handle midnight crossing
    }
  };

  const handleDropdownChange = (event) => {
    const selectedShift = event.target.value;
    const shiftOption = dropdownOptions.find(
      (option) => option.shift === selectedShift
    );

    if (shiftOption) {
      const { starttime, endtime, time, shift } = shiftOption;
      setDynamicStartTime(starttime);
      setDynamicEndTime(endtime);
      setTimelineLabels(time);
      setShift(shift);
    }
  };

  const determineCurrentShift = () => {
    for (const shiftOption of dropdownOptions) {
      if (isCurrentShift(shiftOption)) {
        const { starttime, endtime, time, shift } = shiftOption;
        setDynamicStartTime(starttime);
        setDynamicEndTime(endtime);
        setTimelineLabels(time);
        setShift(shift);
        break;
      }
    }
  };
  useEffect(() => {
    const fetchDataAndAnalytics = async () => {
      try {
        fetchAnalyticsDataSuccess([]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndAnalytics();
  }, []);
  useEffect(() => {
    const fetchDataAndAnalytics = async () => {
      try {
        const formattedDate = selectedDate.toFormat("yyyy-MM-dd");
        await fetchData(formattedDate);
        await fetchAnalyticsData(formattedDate);
        await fetchNocableData(formattedDate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    determineCurrentShift();
    fetchDataAndAnalytics();

    // const intervalId = setInterval(() => {
    //   fetchDataAndAnalytics();
    // }, 120000); // 120000 ms = 120 sec

    // return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [
    fetchAnalyticsData,
    fetchData,
    fetchDataRequest,
    fetchAnalyticsDataRequest,
    selectedDate, // Add selectedDate if it's relevant to triggering the effect
  ]);
  useEffect(() => {
    // let adjustedDate = selectedDate;

    // const currentDate = DateTime.local();
    // const selectedDateStartOfDay = selectedDate.startOf("day");

    // // Apply adjustment only if the selected date is the current date
    // if (selectedDateStartOfDay.equals(currentDate) && currentDate.hour < 7) {
    //   adjustedDate = adjustedDate.minus({ days: 1 }); // Move to the previous day
    // }

    const formattedDate = selectedDate.toFormat("yyyy/MM/dd");
    setSelectedDateString(formattedDate);
  }, [selectedDate]);
  let filteredEntries = objectData.filter(
    (entry) => entry.time !== null && entry.time !== ""
    // && entry.url.toLowerCase().endsWith(".jpg")
  );
  function extractAndCountWords(data) {
    if (!Array.isArray(data)) {
      throw new Error("Data is not an array.");
    }

    const jpgUrls = data.filter(
      (item) => item && item.url && item.url.endsWith(".jpg")
    );
    const wordCounts = {};

    jpgUrls.forEach((item) => {
      if (item.url) {
        const urlParts = item.url.split("/");
        const filename = urlParts[urlParts.length - 1];

        if (filename) {
          const word = filename.split("_")[2]?.split(".")[0]; // Using optional chaining

          if (word) {
            if (wordCounts[word]) {
              wordCounts[word]++;
            } else {
              wordCounts[word] = 1;
            }
          }
        }
      }
    });

    return wordCounts;
  }
  function getMostFrequentDefectType(data) {
    // Create an object to store the frequency count of each defectType
    const frequencyCount = {};

    // Iterate through the data and count the occurrences of each defectType
    data.forEach((item) => {
      const defectType = item.defectType;
      if (defectType) {
        if (frequencyCount[defectType]) {
          frequencyCount[defectType]++;
        } else {
          frequencyCount[defectType] = 1;
        }
      }
    });

    // Find the defectType with the maximum count
    let mostFrequentDefectType = null;
    let maxCount = 0;

    for (const [type, count] of Object.entries(frequencyCount)) {
      if (count > maxCount) {
        maxCount = count;
        mostFrequentDefectType = type;
      }
    }

    return mostFrequentDefectType;
  }
  // Function to find the key with the maximum count, returning the first one in case of ties
  function getMaxCountWord(wordCounts) {
    let maxCount = 0;
    let maxWord = "";

    for (const word in wordCounts) {
      if (wordCounts[word] > maxCount) {
        maxCount = wordCounts[word];
        maxWord = word;
      }
    }

    return maxWord;
  }
  let RSTSort = objectData.filter((item) => item.rst === selectedRST);
  const wordCounts = getMostFrequentDefectType(RSTSort);
  const maxCountWord = getMaxCountWord(wordCounts);
  const sortedTableData = filteredEntries.slice().sort((a, b) => {
    const getTimeInSeconds = (time) => {
      const parts = time.split(":").map(Number);
      let seconds = 0;
      for (let i = 0; i < parts.length; i++) {
        seconds += parts[i] * Math.pow(60, parts.length - 1 - i);
      }
      return seconds;
    };
    let timeA;
    let timeB;
    timeA = getTimeInSeconds(a.time);
    timeB = getTimeInSeconds(b.time);

    return timeB - timeA;
  });

  console.log("sortedTableData", sortedTableData);
  const count =
    sortedTableData.filter(
      (item) =>
        item.rst === selectedRST &&
        item.time !== null &&
        item.time !== undefined &&
        item.time !== ""
    ).length || 0;

  const start =
    sortedTableData.filter(
      (item) =>
        item.rst === selectedRST &&
        item.machineStatus === "START" &&
        item.time !== null &&
        item.time !== undefined &&
        item.time !== ""
    ).length || 0;
  const stop =
    sortedTableData.filter(
      (item) =>
        item.rst === selectedRST &&
        item.machineStatus === "STOP" &&
        item.time !== null &&
        item.time !== undefined &&
        item.time !== ""
    ).length || 0;
  const cageCount = sortedTableData.reduce((acc, item) => {
    const cage = item.cage;

    // Check if the item meets the specified conditions
    if (
      item.rst === selectedRST &&
      item.time !== null &&
      item.time !== undefined &&
      item.time !== ""
    ) {
      if (cage) {
        acc[cage] = (acc[cage] || 0) + 1;
      }
    }

    return acc;
  }, {});

  const maxCage = Object.entries(cageCount).reduce(
    (max, [cage, count]) => {
      return count > max.count ? { cage, count } : max;
    },
    { cage: null, count: -1 }
  );

  const rstEntries =
    sortedTableData.filter((entry) => entry.rst === selectedRST) || [];
  console.log("rstEntries", rstEntries);

  const filterAndSortData = (data, selectedShift) => {
    const shift = shifts[selectedShift];
    if (!shift) {
      console.error("Selected shift not found.");
      return [];
    }

    const { starttime, endtime } = shift;

    // Convert times to minutes
    const timeToMinutes = (time) => {
      if (!time) return 0; // Handle undefined or null time
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const startMinutes = timeToMinutes(starttime);
    const endMinutes = timeToMinutes(endtime);

    const filteredData = data.filter(({ time }) => {
      const minutes = timeToMinutes(time);
      if (startMinutes < endMinutes) {
        // Normal shift (e.g., shift1, shift2)
        return minutes >= startMinutes && minutes <= endMinutes;
      } else {
        // Wrapping shift (e.g., shift3)
        return minutes >= startMinutes || minutes <= endMinutes;
      }
    });

    // Sort by time
    return filteredData.sort(
      (a, b) => timeToMinutes(a.time) - timeToMinutes(b.time)
    );
  };

  // Get the filtered and sorted data
  const filteredAndSortedData = filterAndSortData(rstEntries, selectedShift);

  const filterDataByShift = (data, shiftStart, shiftEnd) => {
    return data.filter((entry) => {
      const entryTime = new Date(`1970-01-01T${entry.time}Z`).getTime();
      const shiftStartTime = new Date(`1970-01-01T${shiftStart}Z`).getTime();
      const shiftEndTime = new Date(`1970-01-01T${shiftEnd}Z`).getTime();

      if (shiftStart < shiftEnd) {
        // For shifts within the same day
        return entryTime >= shiftStartTime && entryTime <= shiftEndTime;
      } else {
        // For shifts that cross midnight
        return (
          entryTime >= shiftStartTime ||
          entryTime <= new Date(`1970-01-02T${shiftEnd}Z`).getTime()
        );
      }
    });
  };
  let customLegend;
const transformData = (data, shiftStart, shiftEnd) => {
  const filteredData = filterDataByShift(data, shiftStart, shiftEnd);

  const uniqueDefectTypes = [
    ...new Set(filteredData.map((entry) => entry.defectType)),
  ];

  const colors = [
    "#FF5733",
    "#1E90FF",
    "#32CD32",
    "#FFC107",
    "#FF9800",
    "#E91E63",
    "#9C27B0",
    "#3F51B5",
  ];

  const colorMap = uniqueDefectTypes.reduce((map, defectType, index) => {
    map[defectType] = colors[index % colors.length];
    return map;
  }, {});

  const excludedTimes = [shiftStart, shiftEnd].map((time) =>
    new Date(`1970-01-01T${time}Z`).getTime()
  );

  const series = [
    {
      name: "Defects",
      data: filteredData
        .filter(
          (entry) =>
            !excludedTimes.includes(
              new Date(`1970-01-01T${entry.time}Z`).getTime()
            )
        )
        .map((entry) => ({
          x:
            new Date(`1970-01-01T${entry.time}Z`).getTime() +
            (entry.time < shiftStart ? 86400000 : 0),
          y: parseFloat(entry.defectAccuracy) || 0,
          fillColor: colorMap[entry.defectType] || "#000",
          defectType: entry.defectType,
          images: entry.images || [],
        })),
    },
  ];

  const minTime = new Date(`1970-01-01T${shiftStart}Z`).getTime();
  const maxTime =
    shiftStart < shiftEnd
      ? new Date(`1970-01-01T${shiftEnd}Z`).getTime()
      : new Date(`1970-01-02T${shiftEnd}Z`).getTime();

  const minAccuracy =
    Math.min(
      ...filteredData.map((entry) => parseFloat(entry.defectAccuracy) || 0)
    ) - 1;

  customLegend = uniqueDefectTypes
    .map(
      (defectType) =>
        `<div style="display: flex;align-items:center;justify-content:center">
        <span style="background-color: ${colorMap[defectType]}; width: 12px; height: 12px; border-radius: 50%; margin-right: 5px;"></span>
        <span style="font-family: PlusJakartaSans; font-size: 12px; color: #000;">${defectType}</span>
      </div>`
    )
    .join("");

  return {
    series,
    options: {
      chart: {
        type: "scatter",
        height: 350,
      },
      xaxis: {
        type: "datetime",
        tickAmount: 8,
        labels: {
          format: "HH:mm",
          style: {
            colors: "#000",
            fontSize: "12px",
            fontFamily: "PlusJakartaSans",
          },
          datetimeFormatter: {
            hour: "HH:mm",
          },
        },
        title: {
          text: "Time",
          style: {
            fontSize: "14px",
            fontFamily: "PlusJakartaSans",
            fontWeight: 500,
            color: "#000",
          },
        },
        min: minTime,
        max: maxTime,
        tickAmount: shiftStart < shiftEnd ? 8 : 24,
      },
      yaxis: {
        min: minAccuracy,
        max: 100,
        tickAmount: 4,
        title: {
          text: "Defect Accuracy (%)",
          style: {
            fontSize: "14px",
            fontFamily: "PlusJakartaSans",
            color: "#000",
            fontWeight: 500,
          },
        },
        labels: {
          style: {
            colors: "#000",
            fontSize: "12px",
            fontFamily: "PlusJakartaSans",
          },
        },
      },
      grid: {
        show: false, // This hides the gridlines
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 0,
      },
      markers: {
        size: 5,
        colors: filteredData.map(
          (entry) => colorMap[entry.defectType] || "#000"
        ),
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const dataPoint = filteredData[dataPointIndex];
          const defectType = dataPoint?.defectType || "Unknown";
          const accuracy =
            series[seriesIndex][dataPointIndex]?.toFixed(2) || "N/A";
          const images = dataPoint?.images || [];
          const firstImageUrl = images.length > 0 ? images[0].url : null;

          return `
        <div style="padding: 10px;">
          <span style="font-family: PlusJakartaSans; font-size: 12px;">
            ${defectType}: ${accuracy}%
          </span>
          ${
            firstImageUrl
              ? `<br><img src="${firstImageUrl}" alt="${defectType}" style="width: 300px; height: auto; margin-top: 10px;" />`
              : ""
          }
        </div>
      `;
        },
        x: {
          format: "HH:mm",
        },
      },
    },
  };
};


  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  const chartData = transformData(filteredAndSortedData, starttime, endtime);
  console.log("chartData", chartData);

  let barData = [
    { text: "Loosewire Stopped", count: 0 },
    { text: "Loosewire Unstopped", count: 0 },
    { text: "Blackwire Stopped", count: 0 },
    { text: "Blackwire Unstopped", count: 0 },
    { text: "Dents Stopped", count: 0 },
    { text: "Dents Unstopped", count: 0 },
    { text: "Patch Stopped", count: 0 },
    { text: "Patch Unstopped", count: 0 },
  ];

  // Helper function to update the barData count
  const updateBarData = (defectType, machineStatus) => {
    const status = machineStatus === "START" ? "Unstopped" : "Stopped";
    const barItem = barData.find(
      (item) => item.text === `${defectType} ${status}`
    );
    if (barItem) {
      barItem.count++;
    }
  };

  // Iterate over the data and update barData
  rstEntries.forEach((item) => {
    updateBarData(item.defectType, item.machineStatus);
  });

  let groupedData = {};
  barData.forEach((item) => {
    const [defectType, status] = item.text.split(" ");
    if (!groupedData[defectType]) {
      groupedData[defectType] = {};
    }
    groupedData[defectType][status] = item.count;
  });

  // Create a sorted array with the condition that if "Stopped" is 0, sort by "Unstopped"
  let sortedBarData = Object.entries(groupedData)
    .sort((a, b) => {
      const stoppedA = a[1]["Stopped"];
      const stoppedB = b[1]["Stopped"];

      // If both stopped counts are 0, sort by "Unstopped" count
      if (stoppedA === 0 && stoppedB === 0) {
        return b[1]["Unstopped"] - a[1]["Unstopped"];
      }

      // Otherwise, sort primarily by "Stopped" count
      return stoppedB - stoppedA;
    })
    .flatMap(([defectType, counts]) => [
      { text: `${defectType} Stopped`, count: counts["Stopped"] },
      { text: `${defectType} Unstopped`, count: counts["Unstopped"] },
    ]);

  function calculateDifference(starttime, endtime) {
    const start = DateTime.utc().set({
      hour: parseInt(starttime.split(":")[0]),
      minute: parseInt(starttime.split(":")[1]),
      second: parseInt(starttime.split(":")[2]),
    });
    const end = DateTime.utc().set({
      hour: parseInt(endtime.split(":")[0]),
      minute: parseInt(endtime.split(":")[1]),
      second: parseInt(endtime.split(":")[2]),
    });

    if (end < start) {
      end.plus({ days: 1 }); // Add one day to end if it's before start
    }

    const diff = end.diff(start);
    const { hours, minutes, seconds } = diff.shiftTo(
      "hours",
      "minutes",
      "seconds"
    ).values;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  function calculateTime(runTime) {
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    runTime.forEach((timePair) => {
      const timeDifference = calculateDifference(
        timePair.starttime,
        timePair.endtime
      );

      totalHours += timeDifference.hours;
      totalMinutes += timeDifference.minutes;
      totalSeconds += timeDifference.seconds;
    });

    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds %= 60;

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
    return {
      hours: totalHours,
      minutes: totalMinutes,
      seconds: totalSeconds,
    };
  }
  const rstData = apiData.find((item) => selectedRST in item) || [];

  const cage24Data = nocableData[selectedRST]?.nocable?.Cage24 || [];
  const cage18Data = nocableData[selectedRST]?.nocable?.Cage18 || [];
  const cage12Data = nocableData[selectedRST]?.nocable?.Cage12 || [];
  const rstSession = rstData[selectedRST]?.session || [];

  const repairTime = rstSession
    .filter((session) => session.event === "repairtime")
    .map((session) => ({
      starttime: session.starttime,
      endtime: session.endtime,
    }));

  const allRepairTime = calculateTime(repairTime);

  const totalRepairTime =
    allRepairTime.hours * 60 +
    allRepairTime.minutes +
    allRepairTime.seconds / 60;
  const stopTime = rstSession
    .filter((session) => session.event === "stoptime")
    .map((session) => ({
      starttime: session.starttime,
      endtime: session.endtime,
    }));

  const allStopTime = calculateTime(stopTime);

  const totalstopTime =
    allStopTime.hours * 60 + allStopTime.minutes + allStopTime.seconds / 60;

  const totalTime = totalRepairTime + totalstopTime;

  const percentageAiTime = Math.round((totalRepairTime / totalTime) * 100);
  const percentageStopTime = 100 - percentageAiTime;

  const pieData = [
    ["Task", "Percentage"],
    [`RST Stopped by AI (${percentageAiTime}%)`, percentageAiTime],
    [`RST Stopped by Operator(${percentageStopTime}%)`, percentageStopTime],
  ];
  const options = {
    is3D: true,
    legend: {
      position: "none",
    },
    chartArea: {
      width: "80%", // Set the width to 100%
      height: "80%", // Set the height to 100%
    },
    colors: ["#A0A6D5", "#D4717F"],
  };

  function calculateRepairTime(events, startTime, endTime) {
    let repairTimeMinutes = 0;

    for (const event of events) {
      if (event.event === "repairtime" && event.starttime && event.endtime) {
        const currentDate = DateTime.utc();
        const startTime = currentDate.set({
          hour: parseInt(event.starttime.split(":")[0]),
          minute: parseInt(event.starttime.split(":")[1]),
          second: parseInt(event.starttime.split(":")[2]),
          millisecond: 0,
        });

        // Create a Luxon DateTime object for the specified end time on the current date
        const endTime = currentDate.set({
          hour: parseInt(event.endtime.split(":")[0]),
          minute: parseInt(event.endtime.split(":")[1]),
          second: parseInt(event.endtime.split(":")[2]),
          millisecond: 0,
        });

        const repairStartTime = startTime.toMillis(); // Output: Unix timestamp for the specified start time on the current date
        const repairEndTime = endTime.toMillis();
        if (repairStartTime >= startTime && repairEndTime <= endTime) {
          repairTimeMinutes += (repairEndTime - repairStartTime) / (1000 * 60); // Convert milliseconds to minutes
        }
      }
    }

    return repairTimeMinutes;
  }

  const shift1StartTimeStr = "07:00:00";
  const shift1EndTimeStr = "15:00:00";

  // const shift2StartTimeStr = "15:00:00";
  // const shift2EndTimeStr = "23:00:00";

  // const shift3StartTimeStr = "23:00:00";
  // const shift3EndTimeStr = "07:00:00";

  // const currentDate = DateTime.utc();

  // const shift1StartTime = currentDate
  //   .set({
  //     hour: parseInt(shift1StartTimeStr.split(":")[0]),
  //     minute: parseInt(shift1StartTimeStr.split(":")[1]),
  //     second: parseInt(shift1StartTimeStr.split(":")[2]),
  //     millisecond: 0,
  //   })
  //   .toMillis();
  // const shift1EndTime = currentDate
  //   .set({
  //     hour: parseInt(shift1EndTimeStr.split(":")[0]),
  //     minute: parseInt(shift1EndTimeStr.split(":")[1]),
  //     second: parseInt(shift1EndTimeStr.split(":")[2]),
  //     millisecond: 0,
  //   })
  // .toMillis();

  // Create Luxon DateTime objects for shift 2 start and end times
  // const shift2StartTime = currentDate
  //   .set({
  //     hour: parseInt(shift2StartTimeStr.split(":")[0]),
  //     minute: parseInt(shift2StartTimeStr.split(":")[1]),
  //     second: parseInt(shift2StartTimeStr.split(":")[2]),
  //     millisecond: 0,
  //   })
  //   .toMillis();
  // const shift2EndTime = currentDate
  //   .set({
  //     hour: parseInt(shift2EndTimeStr.split(":")[0]),
  //     minute: parseInt(shift2EndTimeStr.split(":")[1]),
  //     second: parseInt(shift2EndTimeStr.split(":")[2]),
  //     millisecond: 0,
  //   })
  //   .toMillis();

  // // Create Luxon DateTime objects for shift 3 start and end times
  // const shift3StartTime = currentDate
  //   .set({
  //     hour: parseInt(shift3StartTimeStr.split(":")[0]),
  //     minute: parseInt(shift3StartTimeStr.split(":")[1]),
  //     second: parseInt(shift3StartTimeStr.split(":")[2]),
  //     millisecond: 0,
  //   })
  //   .toMillis();
  // const shift3EndTime = currentDate
  //   .set({
  //     hour: parseInt(shift3EndTimeStr.split(":")[0]),
  //     minute: parseInt(shift3EndTimeStr.split(":")[1]),
  //     second: parseInt(shift3EndTimeStr.split(":")[2]),
  //     millisecond: 0,
  //   })
  //   .toMillis();

  // function timeToSeconds(time) {
  //   if (typeof time === "string") {
  //     return time.split(":").reduce((totalSeconds, timePart, index) => {
  //       const multiplier = [3600, 60, 1][index]; // 3600 seconds in an hour, 60 seconds in a minute
  //       return totalSeconds + parseInt(timePart, 10) * multiplier;
  //     }, 0);
  //   } else {
  //     // Handle the case where 'time' is not a string (e.g., handle the error or return a default value)
  //     return 0; // Defaulting to 0, you can adjust this based on your requirements
  //   }
  // }

  // const trimmedData1 = trimTime(rstSession, shift1StartTime, shift1EndTime);

  // const repairtimeCount1 = trimmedData1.reduce((count, entry) => {
  //   if (entry.event === "repairtime") {
  //     return count + 1;
  //   }
  //   return count;
  // }, 0);

  // const trimmedData2 = trimTime(rstSession, shift2StartTime, shift2EndTime);

  // const repairtimeCount2 = trimmedData2.reduce((count, entry) => {
  //   if (entry.event === "repairtime") {
  //     return count + 1;
  //   }
  //   return count;
  // }, 0);
  let startHours, startMinutes, startSeconds, endHours, endMinutes, endSeconds;

  [startHours, startMinutes, startSeconds] = "23:00:00".split(":").map(Number);
  [endHours, endMinutes, endSeconds] = "23:59:59".split(":").map(Number);

  // const data1 = trimTime(
  //   rstSession,
  //   DateTime.utc().set({
  //     hour: startHours,
  //     minute: startMinutes,
  //     second: startSeconds,
  //     millisecond: 0,
  //   }),
  //   DateTime.utc().set({
  //     hour: endHours,
  //     minute: endMinutes,
  //     second: endSeconds,
  //     millisecond: 0,
  //   })
  // );

  [startHours, startMinutes, startSeconds] = "00:00:00".split(":").map(Number);
  [endHours, endMinutes, endSeconds] = "07:00:00".split(":").map(Number);
  // const data2 = trimTime(
  //   rstSession,
  //   DateTime.utc().set({
  //     hour: startHours,
  //     minute: startMinutes,
  //     second: startSeconds,
  //     millisecond: 0,
  //   }),
  //   DateTime.utc().set({
  //     hour: endHours,
  //     minute: endMinutes,
  //     second: endSeconds,
  //     millisecond: 0,
  //   })
  // );
  // const trimmedData3 = [...data1, ...data2];

  // const repairtimeCount3 = trimmedData3.reduce((count, entry) => {
  //   if (entry.event === "repairtime") {
  //     return count + 1;
  //   }
  //   return count;
  // }, 0);

  // const shift1 =
  //   Math.round(
  //     calculateRepairTime(trimmedData1, shift1StartTime, shift1EndTime) /
  //       repairtimeCount1
  //   ) || 0;
  // const shift2 =
  //   Math.round(
  //     calculateRepairTime(trimmedData2, shift2StartTime, shift2EndTime) /
  //       repairtimeCount2
  //   ) || 0;
  // const shift3 =
  //   Math.round(
  //     calculateRepairTime(trimmedData3, shift3StartTime, shift3EndTime) /
  //       repairtimeCount3
  //   ) || 0;

  // const graphData = {
  //   series: [
  //     {
  //       data: [shift1, shift2, shift3],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "bar",
  //       height: 325,
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: true,
  //         barHeight: 25,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     xaxis: {
  //       categories: ["Shift 1", "Shift 2", "Shift 3"],
  //       title: {
  //         text: "mins",
  //       },
  //     },
  //     yaxis: {
  //       categories: [
  //         30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420,
  //         450, 480,
  //       ],
  //     },
  //     fill: {
  //       type: "gradient",
  //       gradient: {
  //         shade: "dark",
  //         shadeIntensity: 0.5,
  //         opacityFrom: 0.9,
  //         opacityTo: 0.4,
  //         stops: [0, 100],
  //       },
  //       colors: ["#7387E6"],
  //     },
  //   },
  // };

  function trimConductorTime(entries, dynamicStartTime, dynamicEndTime) {
    let result = [];
    let lastEndTime = dynamicStartTime;

    for (const entry of entries) {
      const { event, starttime, endtime } = entry;

      if (starttime && endtime) {
        const entryStartTime = DateTime.utc()
          .set({
            hour: parseInt(starttime.split(":")[0]),
            minute: parseInt(starttime.split(":")[1]),
            second: parseInt(starttime.split(":")[2]),
            millisecond: 0,
          })
          .toMillis();
        const entryEndTime = DateTime.utc()
          .set({
            hour: parseInt(endtime.split(":")[0]),
            minute: parseInt(endtime.split(":")[1]),
            second: parseInt(endtime.split(":")[2]),
            millisecond: 0,
          })
          .toMillis();
        if (entryStartTime < dynamicEndTime && entryEndTime > lastEndTime) {
          const trimmedStartTime = Math.max(
            entryStartTime,
            lastEndTime,
            dynamicStartTime
          );
          const trimmedEndTime = Math.min(entryEndTime, dynamicEndTime);
          result.push({
            event,
            starttime: new Date(trimmedStartTime).toISOString().substr(11, 8),
            endtime: new Date(trimmedEndTime).toISOString().substr(11, 8),
          });
          lastEndTime = entryEndTime;
        }
      }
    }

    return result;
  }

  function trimTime(entries, dynamicStartTime, dynamicEndTime) {
    let result = [];
    let lastEndTime = dynamicStartTime;

    // Check for a gap before the first event
    // if (entries.length > 0) {
    //   const firstEventStartTime = DateTime.utc()
    //     .set({
    //       hour: parseInt(entries[0].starttime.split(":")[0]),
    //       minute: parseInt(entries[0].starttime.split(":")[1]),
    //       second: parseInt(entries[0].starttime.split(":")[2]),
    //       millisecond: 0,
    //     })
    //     .toMillis();

    //   if (dynamicStartTime < firstEventStartTime) {
    //     // Merge gap with the first event
    //     entries[0].starttime = new Date(dynamicStartTime)
    //       .toISOString()
    //       .substr(11, 8);
    //   }
    // }

    for (const entry of entries) {
      const { event, starttime, endtime } = entry;

      if (starttime && endtime) {
        const entryStartTime = DateTime.utc()
          .set({
            hour: parseInt(starttime.split(":")[0]),
            minute: parseInt(starttime.split(":")[1]),
            second: parseInt(starttime.split(":")[2]),
            millisecond: 0,
          })
          .toMillis();
        const entryEndTime = DateTime.utc()
          .set({
            hour: parseInt(endtime.split(":")[0]),
            minute: parseInt(endtime.split(":")[1]),
            second: parseInt(endtime.split(":")[2]),
            millisecond: 0,
          })
          .toMillis();
        if (entryStartTime < dynamicEndTime && entryEndTime > lastEndTime) {
          const trimmedStartTime = Math.max(
            entryStartTime,
            lastEndTime,
            dynamicStartTime
          );
          const trimmedEndTime = Math.min(entryEndTime, dynamicEndTime);
          if (trimmedStartTime > lastEndTime) {
            const notimeDuration = trimmedStartTime - lastEndTime;
            if (notimeDuration < 900000 && result.length > 0) {
              // Merge notime event with previous event
              if (result.length > 0) {
                result[result.length - 1].endtime = new Date(trimmedStartTime)
                  .toISOString()
                  .substr(11, 8);
              }
            } else {
              result.push({
                event: "notime",
                starttime: new Date(lastEndTime).toISOString().substr(11, 8),
                endtime: new Date(trimmedStartTime).toISOString().substr(11, 8),
              });
            }
          }
          result.push({
            event,
            starttime: new Date(trimmedStartTime).toISOString().substr(11, 8),
            endtime: new Date(trimmedEndTime).toISOString().substr(11, 8),
          });
          lastEndTime = entryEndTime;
        }
      }
    }

    if (lastEndTime < dynamicEndTime) {
      const notimeDuration = dynamicEndTime - lastEndTime;
      if (notimeDuration < 900000 && result.length > 0) {
        // Merge notime event with previous event
        // result[result.length - 1].endtime = new Date(dynamicEndTime)
        //   .toISOString()
        //   .substr(11, 8);
      } else {
        result.push({
          event: "notime",
          starttime: new Date(lastEndTime).toISOString().substr(11, 8),
          endtime: new Date(dynamicEndTime).toISOString().substr(11, 8),
        });
      }
    }

    return result;
  }

  // function adjustTimes(data, startTime, endTime) {
  //   return data.reduce((acc, period) => {
  //     const periodStartTime = new Date(`1970-01-01T${period.starttime}`);
  //     const periodEndTime = new Date(`1970-01-01T${period.endtime}`);
  //     const rangeStartTime = new Date(`1970-01-01T${startTime}`);
  //     const rangeEndTime = new Date(`1970-01-01T${endTime}`);

  //     if (
  //       periodEndTime >= periodStartTime &&
  //       periodStartTime >= rangeStartTime &&
  //       periodEndTime <= rangeEndTime
  //     ) {
  //       acc.push({ ...period });
  //     } else if (
  //       periodStartTime < rangeStartTime &&
  //       periodEndTime >= rangeStartTime &&
  //       periodEndTime <= rangeEndTime
  //     ) {
  //       acc.push({ ...period, starttime: startTime });
  //     } else if (
  //       periodEndTime > rangeEndTime &&
  //       periodStartTime >= rangeStartTime &&
  //       periodStartTime <= rangeEndTime
  //     ) {
  //       acc.push({ ...period, endtime: endTime });
  //     } else if (
  //       periodStartTime < rangeStartTime &&
  //       periodEndTime > rangeEndTime
  //     ) {
  //       acc.push({ ...period, starttime: startTime, endtime: endTime });
  //     }

  //     return acc;
  //   }, []);
  // }

  function adjustTimes(data, startTime, endTime) {
    const adjustedTimes = [];
    let currentTime = startTime;

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const startTimeDiff = timeDiff(currentTime, item.starttime);

      if (startTimeDiff > 0) {
        adjustedTimes.push({
          event: "cable",
          starttime: currentTime,
          endtime: item.starttime,
        });
      }

      currentTime = item.starttime;
      adjustedTimes.push(item);

      currentTime = item.endtime;
    }

    if (timeDiff(currentTime, endTime) > 0) {
      adjustedTimes.push({
        event: "cable",
        starttime: currentTime,
        endtime: endTime,
        img_path: "",
      });
    }

    return adjustedTimes;
  }

  function timeDiff(time1, time2) {
    const time1Parts = time1.split(":").map(Number);
    const time2Parts = time2.split(":").map(Number);
    const time1Seconds =
      time1Parts[0] * 3600 + time1Parts[1] * 60 + time1Parts[2];
    const time2Seconds =
      time2Parts[0] * 3600 + time2Parts[1] * 60 + time2Parts[2];
    return time2Seconds - time1Seconds;
  }

  let trimmedData;
  let cage24ShiftData;
  // let cage18ShiftData;

  // let cage12ShiftData;

  if (dynamicStartTime === "23:00:00" && dynamicEndTime === "07:00:00") {
    let currentDateTime = DateTime.local();
    if (currentDateTime.hour < 7) {
      currentDateTime = currentDateTime.minus({ days: 1 });
    }

    function trim(entries, dynamicStartTime, dynamicEndTime) {
      let result = [];
      let lastEndTime = dynamicStartTime;

      for (const entry of entries) {
        const { event, starttime, endtime } = entry;

        if (starttime && endtime) {
          const entryStartTime = DateTime.utc()
            .set({
              hour: parseInt(starttime.split(":")[0]),
              minute: parseInt(starttime.split(":")[1]),
              second: parseInt(starttime.split(":")[2]),
              millisecond: 0,
            })
            .toMillis();
          const entryEndTime = DateTime.utc()
            .set({
              hour: parseInt(endtime.split(":")[0]),
              minute: parseInt(endtime.split(":")[1]),
              second: parseInt(endtime.split(":")[2]),
              millisecond: 0,
            })
            .toMillis();
          if (entryStartTime < dynamicEndTime && entryEndTime > lastEndTime) {
            const trimmedStartTime = Math.max(
              entryStartTime,
              lastEndTime,
              dynamicStartTime
            );
            const trimmedEndTime = Math.min(entryEndTime, dynamicEndTime);

            result.push({
              event,
              starttime: new Date(trimmedStartTime).toISOString().substr(11, 8),
              endtime: new Date(trimmedEndTime).toISOString().substr(11, 8),
            });
            lastEndTime = entryEndTime;
          }
        }
      }

      return result;
    }
    function trim2(entries, dynamicStartTime, dynamicEndTime) {
      let result = [];
      let lastEndTime = dynamicStartTime;

      for (const entry of entries) {
        const { event, starttime, endtime } = entry;

        if (starttime && endtime) {
          const entryStartTime = DateTime.utc()
            .set({
              hour: parseInt(starttime.split(":")[0]),
              minute: parseInt(starttime.split(":")[1]),
              second: parseInt(starttime.split(":")[2]),
              millisecond: 0,
            })
            .toMillis();
          const entryEndTime = DateTime.utc()
            .set({
              hour: parseInt(endtime.split(":")[0]),
              minute: parseInt(endtime.split(":")[1]),
              second: parseInt(endtime.split(":")[2]),
              millisecond: 0,
            })
            .toMillis();
          if (entryStartTime < dynamicEndTime && entryEndTime > lastEndTime) {
            const trimmedStartTime = Math.max(
              entryStartTime,
              lastEndTime,
              dynamicStartTime
            );
            const trimmedEndTime = Math.min(entryEndTime, dynamicEndTime);
            if (trimmedStartTime > lastEndTime) {
              const notimeDuration = trimmedStartTime - lastEndTime;
              if (notimeDuration < 10000 && result.length > 0) {
                // Merge notime event with previous event
                if (result.length > 0) {
                  result[result.length - 1].endtime = new Date(trimmedStartTime)
                    .toISOString()
                    .substr(11, 8);
                }
              } else {
                result.push({
                  event: "notime",
                  starttime: new Date(lastEndTime).toISOString().substr(11, 8),
                  endtime: new Date(trimmedStartTime)
                    .toISOString()
                    .substr(11, 8),
                });
              }
            }
            result.push({
              event,
              starttime: new Date(trimmedStartTime).toISOString().substr(11, 8),
              endtime: new Date(trimmedEndTime).toISOString().substr(11, 8),
            });
            lastEndTime = entryEndTime;
          }
        }
      }

      if (lastEndTime < dynamicEndTime) {
        result.push({
          event: "notime",
          starttime: new Date(lastEndTime).toISOString().substr(11, 8),
          endtime: new Date(dynamicEndTime).toISOString().substr(11, 8),
        });
      }

      return result;
    }

    let data1;

    if (currentDateTime.toISODate() === selectedDate.toISODate()) {
      [startHours, startMinutes, startSeconds] = "23:00:00"
        .split(":")
        .map(Number);
      [endHours, endMinutes, endSeconds] = "23:59:59".split(":").map(Number);
      const startTime = DateTime.utc().set({
        hour: startHours,
        minute: startMinutes,
        second: startSeconds,
        millisecond: 0,
      });

      const endTime = DateTime.utc().set({
        hour: endHours,
        minute: endMinutes,
        second: endSeconds,
        millisecond: 0,
      });
      const hour = currentDateTime.hour;
      const minute = currentDateTime.minute;
      const second = currentDateTime.second;
      const currentTime = DateTime.utc().set({
        hour: hour,
        minute: minute,
        second: second,
        millisecond: 0,
      });
      const trimed = trim(
        rstSession,
        DateTime.utc().set({
          hour: startHours,
          minute: startMinutes,
          second: startSeconds,
          millisecond: 0,
        }),
        DateTime.utc().set({
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
          millisecond: 0,
        })
      );

      if (currentTime >= startTime && currentTime <= endTime) {
        data1 = trimTime(trimed, startTime, currentTime);
      } else if (startTime >= currentTime && endTime >= currentTime) {
        data1 = trimTime(trimed, startTime, startTime);
      } else {
        data1 = trimTime(trimed, startTime, endTime);
      }
    } else {
      [startHours, startMinutes, startSeconds] = "23:00:00"
        .split(":")
        .map(Number);
      [endHours, endMinutes, endSeconds] = "23:59:59".split(":").map(Number);

      const trimed = trim(
        rstSession,
        DateTime.utc().set({
          hour: startHours,
          minute: startMinutes,
          second: startSeconds,
          millisecond: 0,
        }),
        DateTime.utc().set({
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
          millisecond: 0,
        })
      );

      data1 = trim2(
        trimed,
        DateTime.utc().set({
          hour: startHours,
          minute: startMinutes,
          second: startSeconds,
          millisecond: 0,
        }),
        DateTime.utc().set({
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
          millisecond: 0,
        })
      );
    }

    let data2;

    if (currentDateTime.toISODate() === selectedDate.toISODate()) {
      data2 = [];
    } else {
      [startHours, startMinutes, startSeconds] = "00:00:00"
        .split(":")
        .map(Number);
      [endHours, endMinutes, endSeconds] = "07:00:00".split(":").map(Number);

      const trimed = trim(
        rstSession,
        DateTime.utc().set({
          hour: startHours,
          minute: startMinutes,
          second: startSeconds,
          millisecond: 0,
        }),
        DateTime.utc().set({
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
          millisecond: 0,
        })
      );

      data2 = trimTime(
        trimed,
        DateTime.utc().set({
          hour: startHours,
          minute: startMinutes,
          second: startSeconds,
          millisecond: 0,
        }),
        DateTime.utc().set({
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
          millisecond: 0,
        })
      );
    }

    let changedEvent1;
    if (data1.length === 1) {
      if (
        data1[0].starttime === "00:00:00" &&
        data1[0].endtime === "07:00:00"
      ) {
        data1[0].event = "notime";
      }
      changedEvent1 = data1;
    } else {
      changedEvent1 = data1;
    }

    let changedEvent2;
    if (data2.length === 1) {
      if (
        data2[0].starttime === "00:00:00" &&
        data2[0].endtime === "07:00:00"
      ) {
        data2[0].event = "notime";
      }
      changedEvent2 = data2;
    } else {
      changedEvent2 = data2;
    }

    trimmedData = Array.from([changedEvent1, changedEvent2]).flat();

    function mergeEvents(events) {
      if (events.length === 0) return [];

      let mergedEvents = [];
      let currentEvent = events[0];

      for (let i = 1; i < events.length; i++) {
        if (events[i].event === currentEvent.event) {
          // Update the end time of the current event to the end time of the latest event
          currentEvent.endtime = events[i].endtime;
        } else {
          // Push the current event to the mergedEvents list
          mergedEvents.push(currentEvent);
          // Start a new current event
          currentEvent = events[i];
        }
      }
      // Don't forget to push the last event
      mergedEvents.push(currentEvent);

      return mergedEvents;
    }

    [startHours, startMinutes, startSeconds] = "23:00:00"
      .split(":")
      .map(Number);
    [endHours, endMinutes, endSeconds] = "23:59:59".split(":").map(Number);

    const cage24Data1 = trimConductorTime(
      cage24Data,
      DateTime.utc().set({
        hour: startHours,
        minute: startMinutes,
        second: startSeconds,
        millisecond: 0,
      }),
      DateTime.utc().set({
        hour: endHours,
        minute: endMinutes,
        second: endSeconds,
        millisecond: 0,
      })
    );

    [startHours, startMinutes, startSeconds] = "00:00:00"
      .split(":")
      .map(Number);
    [endHours, endMinutes, endSeconds] = "07:00:00".split(":").map(Number);
    const cage24Data2 = trimConductorTime(
      cage24Data,
      DateTime.utc().set({
        hour: startHours,
        minute: startMinutes,
        second: startSeconds,
        millisecond: 0,
      }),
      DateTime.utc().set({
        hour: endHours,
        minute: endMinutes,
        second: endSeconds,
        millisecond: 0,
      })
    );
    const cage24ShiftData1 = adjustTimes(cage24Data1, "23:00:00", "23:59:59");

    const cage24ShiftData2 = adjustTimes(cage24Data2, "00:00:00", "07:00:00");

    if (currentDateTime.toISODate() === selectedDate.toISODate()) {
      console.log("currentDateTime", currentDateTime.hour);
      if (currentDateTime.hour >= 23) {
        cage24ShiftData = [...cage24ShiftData1];
      } else if (currentDateTime.hour < 7) {
        cage24ShiftData = Array.from([
          cage24ShiftData1,
          cage24ShiftData2,
        ]).flat();
      } else {
        cage24ShiftData = [];
      }
    } else {
      cage24ShiftData = Array.from([cage24ShiftData1, cage24ShiftData2]).flat();
    }
  } else {
    [startHours, startMinutes, startSeconds] = dynamicStartTime
      .split(":")
      .map(Number);
    const [endHours, endMinutes, endSeconds] = dynamicEndTime
      .split(":")
      .map(Number);
    const endTimeSeconds = endHours * 3600 + endMinutes * 60 + endSeconds;

    const currentDateTime = DateTime.local();
    const currentTimeSeconds =
      currentDateTime.hour * 3600 +
      currentDateTime.minute * 60 +
      currentDateTime.second;
    const startTime = DateTime.utc().set({
      hour: startHours,
      minute: startMinutes,
      second: startSeconds,
      millisecond: 0,
    });

    const endTime = DateTime.utc().set({
      hour: endHours,
      minute: endMinutes,
      second: endSeconds,
      millisecond: 0,
    });
    const hour = currentDateTime.hour;
    const minute = currentDateTime.minute;
    const second = currentDateTime.second;
    const currentTime = DateTime.utc().set({
      hour: hour,
      minute: minute,
      second: second,
      millisecond: 0,
    });

    function trim(entries, dynamicStartTime, dynamicEndTime) {
      let result = [];
      let lastEndTime = dynamicStartTime;

      for (const entry of entries) {
        const { event, starttime, endtime } = entry;

        if (starttime && endtime) {
          const entryStartTime = DateTime.utc()
            .set({
              hour: parseInt(starttime.split(":")[0]),
              minute: parseInt(starttime.split(":")[1]),
              second: parseInt(starttime.split(":")[2]),
              millisecond: 0,
            })
            .toMillis();
          const entryEndTime = DateTime.utc()
            .set({
              hour: parseInt(endtime.split(":")[0]),
              minute: parseInt(endtime.split(":")[1]),
              second: parseInt(endtime.split(":")[2]),
              millisecond: 0,
            })
            .toMillis();
          if (entryStartTime < dynamicEndTime && entryEndTime > lastEndTime) {
            const trimmedStartTime = Math.max(
              entryStartTime,
              lastEndTime,
              dynamicStartTime
            );
            const trimmedEndTime = Math.min(entryEndTime, dynamicEndTime);

            result.push({
              event,
              starttime: new Date(trimmedStartTime).toISOString().substr(11, 8),
              endtime: new Date(trimmedEndTime).toISOString().substr(11, 8),
            });
            lastEndTime = entryEndTime;
          }
        }
      }

      return result;
    }

    const trimed = trim(
      rstSession,
      DateTime.utc().set({
        hour: startHours,
        minute: startMinutes,
        second: startSeconds,
        millisecond: 0,
      }),
      DateTime.utc().set({
        hour: endHours,
        minute: endMinutes,
        second: endSeconds,
        millisecond: 0,
      })
    );
    function mergeEvents(events) {
      if (events.length === 0) return [];

      let mergedEvents = [];
      let currentEvent = events[0];

      for (let i = 1; i < events.length; i++) {
        if (events[i].event === currentEvent.event) {
          // Update the end time of the current event to the end time of the latest event
          currentEvent.endtime = events[i].endtime;
        } else {
          // Push the current event to the mergedEvents list
          mergedEvents.push(currentEvent);
          // Start a new current event
          currentEvent = events[i];
        }
      }
      // Don't forget to push the last event
      mergedEvents.push(currentEvent);

      return mergedEvents;
    }
    console.log("trimed", trimed.length);
    if (currentDateTime.toISODate() === selectedDate.toISODate()) {
      if (currentTime >= startTime && currentTime <= endTime) {
        const uniqueData = trimTime(trimed, startTime, currentTime);

        trimmedData = mergeEvents(uniqueData);
      } else if (startTime >= currentTime && endTime >= currentTime) {
        const uniqueData = trimTime(trimed, startTime, startTime);
        trimmedData = mergeEvents(uniqueData);
      } else {
        const uniqueData = trimTime(trimed, startTime, endTime);
        trimmedData = mergeEvents(uniqueData);
      }
    } else {
      const uniqueData = trimTime(
        rstSession,
        DateTime.utc().set({
          hour: startHours,
          minute: startMinutes,
          second: startSeconds,
          millisecond: 0,
        }),
        DateTime.utc().set({
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
          millisecond: 0,
        })
      );
      trimmedData = mergeEvents(uniqueData);
    }

    [startHours, startMinutes, startSeconds] = dynamicStartTime
      .split(":")
      .map(Number);
    const startDateTime = DateTime.utc().set({
      hour: startHours,
      minute: startMinutes,
      second: startSeconds,
      millisecond: 0,
    });

    let endDateTime = DateTime.utc().set({
      hour: endHours,
      minute: endMinutes,
      second: endSeconds,
      millisecond: 0,
    });

    let cage24;

    function merge30secEvents(events) {
      if (!events || events.length === 0) {
        return [];
      }

      let mergedEvents = [];
      let currentEvent = { ...events[0] };

      for (let i = 1; i < events.length; i++) {
        const currentEndtimeSeconds = timeToSeconds(currentEvent.endtime);
        const nextStarttimeSeconds = timeToSeconds(events[i].starttime);

        if (nextStarttimeSeconds - currentEndtimeSeconds <= 30) {
          // Merge events
          currentEvent.endtime = events[i].endtime;
        } else {
          // Push the current merged event and start a new one
          mergedEvents.push({ ...currentEvent });
          currentEvent = { ...events[i] };
        }
      }

      // Push the last merged event
      mergedEvents.push({ ...currentEvent });

      return mergedEvents;
    }

    function timeToSeconds(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }

    const eventsWithout30SecGap = merge30secEvents(cage24Data);

    // Check if the current date matches the selected date
    if (currentDateTime.toISODate() === selectedDate.toISODate()) {
      // Check if the current time is before the end time
      if (currentTimeSeconds < endTimeSeconds) {
        const currentDateTimeNoMillis = DateTime.utc().set({
          hour: currentDateTime.hour,
          minute: currentDateTime.minute,
          second: currentDateTime.second,
          millisecond: 0,
        });

        // Trim cage24Data based on the current time
        cage24 = trimConductorTime(
          eventsWithout30SecGap,
          startDateTime,
          currentDateTimeNoMillis
        );

        const ShiftData = adjustTimes(
          cage24,
          dynamicStartTime,
          currentDateTimeNoMillis.toFormat("HH:mm:ss")
        );
        cage24ShiftData = mergeEvents(ShiftData);
      } else {
        const endDateTime = DateTime.utc().set({
          hour: endHours,
          minute: endMinutes,
          second: endSeconds,
          millisecond: 0,
        });

        // Trim cage24Data based on the end time
        cage24 = trimConductorTime(
          eventsWithout30SecGap,
          startDateTime,
          endDateTime
        );

        const ShiftData = adjustTimes(
          cage24,
          dynamicStartTime,
          endDateTime.toFormat("HH:mm:ss")
        );

        cage24ShiftData = mergeEvents(ShiftData);
      }
    } else {
      const endDateTime = DateTime.utc().set({
        hour: endHours,
        minute: endMinutes,
        second: endSeconds,
        millisecond: 0,
      });

      // Trim cage24Data based on the end time
      cage24 = trimConductorTime(
        eventsWithout30SecGap,
        startDateTime,
        endDateTime
      );

      const ShiftData = adjustTimes(
        cage24,
        dynamicStartTime,
        endDateTime.toFormat("HH:mm:ss")
      );

      cage24ShiftData = mergeEvents(ShiftData);
    }
  }

  let totalStopTime = 0;
  let totalRunTime = 0;
  let totalNodata = 0;

  trimmedData.forEach((entry) => {
    const startTime = new Date(`2000-01-01 ${entry.starttime}`);
    const endTime = new Date(`2000-01-01 ${entry.endtime}`);
    const duration = (endTime - startTime) / 1000; // Duration in seconds

    if (entry.event === "stoptime") {
      totalStopTime += duration;
    } else if (entry.event === "runtime") {
      totalRunTime += duration;
    } else if (entry.event === "notime") {
      totalNodata += duration;
    }
  });
  let cableTime = 0;
  let nocableTime = 0;

  cage24ShiftData.forEach((entry) => {
    const startTime = new Date(`2000-01-01 ${entry.starttime}`);
    const endTime = new Date(`2000-01-01 ${entry.endtime}`);
    const duration = (endTime - startTime) / 1000; // Duration in seconds

    if (entry.event === "cable") {
      cableTime += duration;
    } else if (entry.event === "nocable") {
      nocableTime += duration; // Corrected variable name
    }
  });
  // Function to format seconds to hh:mm
  function Time(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    if (hours > 0) {
      return `${hours}hr ${minutes}min`;
    } else if (minutes >= 1) {
      return `${minutes}min`;
    } else {
      return `${seconds}sec`;
    }
  }

  const data = [
    {
      color: "#AAC5B9",
      label: "Machine Runtime",
      time: Time(totalRunTime),
    },
    {
      color: "#D4717F",
      label: "Manual Stopped",
      time: Time(totalStopTime),
    },
    {
      color: "rgb(146 48 68)",
      label: "No Data",
      time: Time(totalNodata),
    },
  ];

  const sortedData = trimmedData.sort((a, b) => {
    // Convert start time to milliseconds since midnight
    const timeA = a.starttime.startsWith("23")
      ? new Date(`2000-01-01T${a.starttime}Z`).getTime()
      : new Date(`2000-01-02T${a.starttime}Z`).getTime();
    const timeB = b.starttime.startsWith("23")
      ? new Date(`2000-01-01T${b.starttime}Z`).getTime()
      : new Date(`2000-01-02T${b.starttime}Z`).getTime();

    return timeA - timeB;
  });

  function calculateTimeDifference(start, end) {
    const startTime = new Date(`1970-01-01T${start}`);
    const endTime = new Date(`1970-01-01T${end}`);
    const differenceInMilliseconds = endTime - startTime;
    const minutes = differenceInMilliseconds / (1000 * 60);
    return minutes;
  }

  function calculateTotalTime(entries) {
    let totalTime = 0;

    const result = entries.map((entry) => {
      const sessionTimes = {};

      const startTime = entry.starttime;
      const endTime = entry.endtime;
      let timeInMinutes = calculateTimeDifference(startTime, endTime);

      if (entry.event === "runtime" && entry.toggleoff) {
        const toggleOffStartTime = entry.toggleoff.starttime;
        const toggleOffEndTime = entry.toggleoff.endtime;
        const overlapTime = calculateTimeDifference(
          Math.max(startTime, toggleOffStartTime),
          Math.min(endTime, toggleOffEndTime)
        );

        if (overlapTime > 0) {
          timeInMinutes -= overlapTime;
        }
      }

      sessionTimes[entry.event] = timeInMinutes;
      totalTime += timeInMinutes;

      return sessionTimes;
    });

    return { result };
  }
  const cage24time = cage24ShiftData
    .map((item) => {
      const nocable = calculateTimeDifference(item.starttime, item.endtime);
      return { [item.event]: nocable };
    })
    .filter((item) => item.nocable !== 0);
  // const cage18time = cage18ShiftData
  //   .map((item) => {
  //     const nocable = calculateTimeDifference(item.starttime, item.endtime);
  //     return { [item.event]: nocable };
  //   })
  //   .filter((item) => item.nocable !== 0);

  // const cage12time = cage12ShiftData
  //   .map((item) => {
  //     const nocable = calculateTimeDifference(item.starttime, item.endtime);
  //     return { [item.event]: nocable };
  //   })
  //   .filter((item) => item.nocable !== 0);

  const filteredSessionTotalTime = calculateTotalTime(sortedData) || [];
  const sessionDetails = filteredSessionTotalTime.result;
  const nonNegativeSessionDetails =
    sessionDetails.filter((entry) => {
      for (const activity in entry) {
        if (entry[activity] < 0) {
          return false;
        }
      }
      return true;
    }) || [];

  const colors = {
    notime: "rgb(146 48 68)",
    runtime: "#AAC5B9",
    repairtime: "#A0A6D5",
    toggleoff: "#E2C6AB",
    stoptime: "#D4717F",
  };
  let gradientString = "";
  let currentPosition = 0;
  for (const session of nonNegativeSessionDetails) {
    for (const activity in session) {
      const duration = session[activity] * 0.2083333333333333;

      if (currentPosition + duration > 100) {
        const adjustedDuration = 100 - currentPosition;
        gradientString += `${colors[activity]} ${currentPosition}%, ${
          colors[activity]
        } ${currentPosition + adjustedDuration}%, `;
        currentPosition = 100;
        break;
      }

      gradientString += `${colors[activity]} ${currentPosition}%, ${
        colors[activity]
      } ${currentPosition + duration}%, `;
      currentPosition += duration;
    }

    if (currentPosition >= 100) {
      break;
    }
  }

  if (isNaN(currentPosition) || typeof currentPosition === "undefined") {
    gradientString = `#FFF 0%, #FFF 100%, `;
  } else {
    if (currentPosition < 100) {
      gradientString += `#FFF ${currentPosition}%, #FFF 100%, `;
    }
  }
  gradientString = gradientString.slice(0, -2);

  function generateGradientString(colors, sessions) {
    let gradientString = "";
    let currentPosition = 0;

    for (const session of sessions) {
      for (const activity in session) {
        const duration = session[activity] * 0.2083333333333333;

        if (currentPosition + duration > 100) {
          const adjustedDuration = 100 - currentPosition;
          gradientString += `${colors[activity]} ${currentPosition}%, ${
            colors[activity]
          } ${currentPosition + adjustedDuration}%, `;
          currentPosition = 100;
          break;
        }

        gradientString += `${colors[activity]} ${currentPosition}%, ${
          colors[activity]
        } ${currentPosition + duration}%, `;
        currentPosition += duration;
      }

      if (currentPosition >= 100) {
        break;
      }
    }

    if (isNaN(currentPosition) || typeof currentPosition === "undefined") {
      gradientString = `#FFF 0%, #FFF 100%, `;
    } else {
      if (currentPosition < 100) {
        gradientString += `#FFF ${currentPosition}%, #FFF 100%, `;
      }
    }
    gradientString = gradientString.slice(0, -2);
    return gradientString;
  }

  // Example usage:
  const cagecolors = {
    cable: "#97AECE",
    nocable: "#ECA45B",
  };
  const cage24labels = [
    // {
    //   color: "#97AECE",
    //   label: "Conductor Avalible",
    //   time: Time(cableTime),
    // },
    {
      color: "#ECA45B",
      label: "No Conductor",
      time: Time(nocableTime),
    },
  ];
  const cage24ColorData = generateGradientString(cagecolors, cage24time);

  // const cage18ColorData = generateGradientString(cagecolors, cage18time);
  // const cage12ColorData = generateGradientString(cagecolors, cage12time);

  const timesArray = sortedTableData
    .filter(
      (item) =>
        item.rst === selectedRST &&
        item.time !== null &&
        item.time !== undefined &&
        item.time !== ""
    )
    .map((entry) => entry.time);
  console.log("timesArray", timesArray);
  function timeDifferenceInSeconds(time1, time2) {
    const date1 = new Date(`2000-01-01T${time1}`);
    const date2 = new Date(`2000-01-01T${time2}`);
    return Math.abs((date2 - date1) / 1000);
  }

  const differences = timesArray
    .slice(1)
    .map((time, i) => timeDifferenceInSeconds(timesArray[i], time));

  const fourTimes = differences.slice(0, 4);

  const sumFirstFour = fourTimes.reduce((total, num) => total + num, 0);

  function time(durationInSeconds) {
    if (!isFinite(durationInSeconds) || isNaN(durationInSeconds)) {
      return "--";
    } else if (durationInSeconds < 60) {
      return `${Math.floor(durationInSeconds)} sec`;
    } else if (durationInSeconds < 3600) {
      // Less than 1 hour, display as minutes and seconds
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = Math.floor(durationInSeconds % 60);
      return `${minutes} min ${seconds} sec`;
    } else {
      // 1 hour or more, display as hours and minutes
      const hours = Math.floor(durationInSeconds / 3600);
      const remainingSeconds = durationInSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      return `${hours} hours ${minutes} min`;
    }
  }
  const TimeFormat = time(Math.round(sumFirstFour / fourTimes.length));
  const avgRepair =
    totalRepairTime === 0
      ? "00"
      : Math.round((totalRepairTime / repairTime.length) * 60)
          .toString()
          .padStart(2, "0");
  const avgRepairTime = time(avgRepair);
  const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  const cardDetails = [
    {
      type: "combined",
      text: ["stop Defects", "Unstopped Defects"],
      counts: [
        (stop ?? 0).toString().padStart(2, "0") || "--",
        (start ?? 0).toString().padStart(2, "0") || "--", // Replace 'start' with your actual variable
        // Replace 'stop' with your actual variable
      ],
    },
    {
      text: "Most Frequent Cage",
      count: maxCage.cage || "N/A", // Provide a default value like "N/A" if maxCage.cage is falsy
    },
    {
      text: "Most Frequent Defect",
      count: wordCounts || "N/A", // Provide a default value like "N/A" if wordCounts is falsy
    },
    {
      text: "Defect Frequency",
      count: TimeFormat || "N/A", // Provide a default value like "N/A" if TimeFormat is falsy
    },
  ];

  const formatEntriesDate = (dateString) => {
    const [year, month, day] = dateString.split("/");
    return `${day}/${month}/${year}`;
  };
  const handleCalenderToggle = () => {
    setCalenderOpen(!calenderOpen);
  };
  const handleDateChange = (date) => {
    const luxonDate = DateTime.fromJSDate(date);
    setSelectedDate(luxonDate);
    setCalenderOpen(false);
  };

  // function handleHover(event) {
  //   const timelineWidth = event.target.offsetWidth;
  //   const timelinePosition =
  //     event.clientX - event.target.getBoundingClientRect().left;
  //   const divisor = 0.2083333333333333;

  //   const data = nonNegativeSessionDetails.map((obj) => {
  //     const newObj = {};
  //     for (const key in obj) {
  //       newObj[key] = obj[key] * divisor;
  //     }
  //     return newObj;
  //   });

  //   const percentageRanges = [];
  //   let startPercentage = 0;
  //   let endPercentage = 0;

  //   for (const obj of data) {
  //     const key = Object.keys(obj)[0];
  //     const value = obj[key];

  //     endPercentage += value;
  //     const percentageObj = {
  //       [key]: `${startPercentage}% to ${endPercentage}%`,
  //     };
  //     percentageRanges.push(percentageObj);

  //     startPercentage = endPercentage;
  //   }

  //   // Adjust the last percentage range to end at 100%
  //   percentageRanges[percentageRanges.length - 1][
  //     Object.keys(data[data.length - 1])[0]
  //   ] = `${
  //     percentageRanges[percentageRanges.length - 1][
  //       Object.keys(data[data.length - 1])[0]
  //     ]
  //   }`;

  //   const hoveredPercentage = (timelinePosition / timelineWidth) * 100;
  //   const index = findIndexByPercentage(hoveredPercentage, percentageRanges);
  //   const type = [
  //     "Machine Runtime",
  //     "Defect Repair Time",
  //     "Manual Stopped",
  //     "AI Off",
  //     "no time",
  //   ];

  //   let eventText = sortedData[index]?.event; // Use optional chaining (?.) to check if sortedData[index] exists
  //   if (eventText === "runtime") {
  //     eventText = type[0]; // Machine Runtime
  //   } else if (eventText === "repairtime") {
  //     eventText = type[1]; // Defect Repair Time
  //   } else if (eventText === "stoptime") {
  //     eventText = type[2]; // Manual Stopped
  //   } else if (eventText === "toggleoff") {
  //     eventText = type[3]; // AI Off
  //   } else {
  //     eventText = type[4]; // AI Off
  //   }

  //   setHoveredEvent(
  //     eventText +
  //       " : " +
  //       (sortedData[index]?.starttime ?? "N/A") +
  //       " - " +
  //       (sortedData[index]?.endtime ?? "N/A")
  //   );

  //   const x = event.clientX; // Mouse X coordinate
  //   const y = event.clientY; // Mouse Y coordinate
  //   const popup = document.querySelector(".popup");

  //   if (popup) {
  //     const popupWidth = popup.offsetWidth;
  //     const popupHeight = popup.offsetHeight;
  //     const screenWidth = window.innerWidth;
  //     const screenHeight = window.innerHeight;

  //     let popupX = x; // Initial X position
  //     let popupY = y; // Initial Y position

  //     // Adjust X position if popup would go off-screen to the right
  //     if (x + popupWidth > screenWidth) {
  //       popupX = screenWidth - popupWidth;
  //     }

  //     // Adjust Y position if popup would go off-screen at the bottom
  //     if (y + popupHeight > screenHeight) {
  //       popupY = screenHeight - popupHeight;
  //     }

  //     popup.style.top = `${popupY}px`;
  //     popup.style.left = `${popupX}px`;
  //   }
  // }

  function handleHover(event) {
    const timelineWidth = event.target.offsetWidth;
    const timelinePosition =
      event.clientX - event.target.getBoundingClientRect().left;
    const divisor = 0.2083333333333333;

    if (!nonNegativeSessionDetails) {
      console.error("nonNegativeSessionDetails is undefined or null");
      return;
    }

    const data = nonNegativeSessionDetails.map((obj) => {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = obj[key] * divisor;
        }
      }
      return newObj;
    });

    const percentageRanges = [];
    let startPercentage = 0;
    let endPercentage = 0;

    for (const obj of data) {
      const key = Object.keys(obj)[0];
      if (key) {
        const value = obj[key];

        endPercentage += value;
        const percentageObj = {
          [key]: `${startPercentage}% to ${endPercentage}%`,
        };
        percentageRanges.push(percentageObj);

        startPercentage = endPercentage;
      }
    }

    // Adjust the last percentage range to end at 100%
    if (percentageRanges.length > 0) {
      const lastKey = Object.keys(data[data.length - 1])[0];
      if (lastKey) {
        percentageRanges[percentageRanges.length - 1][lastKey] = `${
          percentageRanges[percentageRanges.length - 1][lastKey]
        }`;
      }
    }

    const hoveredPercentage = (timelinePosition / timelineWidth) * 100;
    const index = findIndexByPercentage(hoveredPercentage, percentageRanges);

    if (!sortedData || !sortedData[index]) {
      console.error("sortedData or sortedData[index] is undefined or null");
      return;
    }

    const type = ["Machine Runtime", "Manual Stopped", "no Data"];

    let eventText = sortedData[index]?.event;
    if (eventText === "runtime") {
      eventText = type[0]; // Machine Runtime
    } else if (eventText === "stoptime") {
      eventText = type[1]; // Manual Stopped
    } else {
      eventText = type[2]; // AI Off
    }

    setHoveredEvent(
      eventText +
        " : " +
        (sortedData[index]?.starttime ?? "N/A") +
        " - " +
        (sortedData[index]?.endtime ?? "N/A")
    );

    const x = event.clientX; // Mouse X coordinate
    const y = event.clientY; // Mouse Y coordinate
    const popup = document.querySelector(".popup");

    if (popup) {
      const popupWidth = popup.offsetWidth;
      const popupHeight = popup.offsetHeight;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      let popupX = x; // Initial X position
      let popupY = y; // Initial Y position

      // Adjust X position if popup would go off-screen to the right
      if (x + popupWidth > screenWidth) {
        popupX = screenWidth - popupWidth;
      }

      // Adjust Y position if popup would go off-screen at the bottom
      if (y + popupHeight > screenHeight) {
        popupY = screenHeight - popupHeight;
      }

      popup.style.top = `${popupY}px`;
      popup.style.left = `${popupX}px`;
    }
  }

  function handleCageHover(event) {
    const timelineWidth = event.target.offsetWidth;
    const timelinePosition =
      event.clientX - event.target.getBoundingClientRect().left;
    const divisor = 0.2083333333333333;
    if (!cage24time) {
      console.error("nonNegativeSessionDetails is undefined or null");
      return;
    }

    const data = cage24time.map((obj) => {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = obj[key] * divisor;
        }
      }
      return newObj;
    });
    const percentageRanges = [];
    let startPercentage = 0;
    let endPercentage = 0;

    for (const obj of data) {
      const key = Object.keys(obj)[0];
      if (key) {
        const value = obj[key];

        endPercentage += value;
        const percentageObj = {
          [key]: `${startPercentage}% to ${endPercentage}%`,
        };
        percentageRanges.push(percentageObj);

        startPercentage = endPercentage;
      }
    }

    // Adjust the last percentage range to end at 100%
    if (percentageRanges.length > 0) {
      const lastKey = Object.keys(data[data.length - 1])[0];
      if (lastKey) {
        percentageRanges[percentageRanges.length - 1][lastKey] = `${
          percentageRanges[percentageRanges.length - 1][lastKey]
        }`;
      }
    }

    const hoveredPercentage = (timelinePosition / timelineWidth) * 100;

    const index = findIndexByPercentage(hoveredPercentage, percentageRanges);

    if (!cage24ShiftData || !cage24ShiftData[index]) {
      console.error("sortedData or sortedData[index] is undefined or null");
      return;
    }

    const type = ["Conductor Avalible", "No Conductor"];

    let eventText = cage24ShiftData[index]?.event;

    if (eventText === "cable") {
      eventText = type[0]; // Machine Runtime
    } else if (eventText === "nocable") {
      eventText = type[1]; // Manual Stopped
    }

    setHoveredCage24Event(
      eventText +
        " : " +
        (cage24ShiftData[index]?.starttime ?? "N/A") +
        " - " +
        (cage24ShiftData[index]?.endtime ?? "N/A")
    );

    const x = event.clientX; // Mouse X coordinate
    const y = event.clientY; // Mouse Y coordinate
    const popup = document.querySelector(".cage24popup");

    if (popup) {
      const popupWidth = popup.offsetWidth;
      const popupHeight = popup.offsetHeight;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      let popupX = x; // Initial X position
      let popupY = y; // Initial Y position

      // Adjust X position if popup would go off-screen to the right
      if (x + popupWidth > screenWidth) {
        popupX = screenWidth - popupWidth;
      }

      // Adjust Y position if popup would go off-screen at the bottom
      if (y + popupHeight > screenHeight) {
        popupY = screenHeight - popupHeight;
      }

      popup.style.top = `${popupY}px`;
      popup.style.left = `${popupX}px`;
    }
  }

  function findIndexByPercentage(percentage, percentageRanges) {
    let index = -1;

    for (let i = 0; i < percentageRanges.length; i++) {
      const rangeKey = Object.keys(percentageRanges[i])[0];
      if (rangeKey) {
        const range = percentageRanges[i][rangeKey];
        const [start, end] = range.split(" to ");

        if (percentage >= parseFloat(start) && percentage <= parseFloat(end)) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  const handleOutsideClick = (event) => {
    if (
      calenderOpen &&
      !event.target.closest(".analytics-date-button") &&
      !event.target.closest(".analytics-calender-container")
    ) {
      setCalenderOpen(false); // Close the calendar if clicked outside
    }
  };

  // Use the handleOutsideClick function in useEffect
  useEffect(() => {
    if (calenderOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [calenderOpen, handleOutsideClick]);
  const disableFutureDates = ({ date, view }) => {
    if (view === "month") {
      const now = DateTime.local();
      const adjustedNow =
        now.hour < 7
          ? now.minus({ days: 1 }).startOf("day")
          : now.startOf("day");
      return date > adjustedNow.toJSDate();
    }
    return false;
  };
  const hasData =
    chartData.series &&
    chartData.series.length > 0 &&
    chartData.series[0].data.length > 0;

  return (
    <div className="main">
      {rstData.length === 0 &&
      (apiDataLoading || objectDataLoading || nocableDataLoading) ? (
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="analytics-main">
          <div className="calender">
            <button
              className="analytics-date-button"
              disabled={apiDataLoading || nocableDataLoading}
              onClick={handleCalenderToggle}
            >
              {formatEntriesDate(selectedDateString)}
              <SlArrowDown className="calender-down-arrow-icon" />
            </button>
            {calenderOpen && (
              <div className="analytics-calender-container">
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  tileDisabled={disableFutureDates}
                />
              </div>
            )}
          </div>
          <div className="heatmap-container">
            <div className="card2">
              <div className="flex-heatmap">
                <div>
                  <span className="analytics-graph-title">
                    Heatmap on Selected Shift
                  </span>
                </div>
                <div className="box-container">
                  <div className="dropdown-align">
                    <select
                      className="dropdown"
                      value={shift}
                      onChange={handleDropdownChange}
                    >
                      {dropdownOptions.map((option) => (
                        <option key={option.shift} value={option.shift}>
                          {option.shift}
                        </option>
                      ))}
                    </select>
                  </div>
                  {data.map((item, index) => (
                    <div className="box-set" key={index}>
                      <div
                        className="box"
                        style={{ backgroundColor: item.color }}
                      ></div>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="box-label">{item.label}</div>

                        <div>
                          <i>
                            <b>{`(${item.time})`}</b>
                          </i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class="timeline-container">
                <center>
                  <div className="timeline-container">
                    <div
                      className="timeline-line"
                      style={{
                        background: gradientString
                          ? `linear-gradient(to right, ${gradientString})`
                          : "white",
                      }}
                      onMouseOver={(e) => handleHover(e, currentPosition)}
                    ></div>
                    {hoveredEvent && (
                      <div className="popup">
                        <pre>{hoveredEvent}</pre>
                      </div>
                    )}
                  </div>
                </center>
                <div className="timeline-labels">
                  {timelineLabels.map((label, index) => (
                    <div key={index} className="timeline-label">
                      {label}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex-heatmap">
                <div>
                  <span className="analytics-graph-title">Cage24</span>
                </div>
                <div className="box-container">
                  <div className="dropdown-align"></div>
                  {cage24labels.map((item, index) => (
                    <div className="box-set" key={index}>
                      <div
                        className="box"
                        style={{ backgroundColor: item.color }}
                      ></div>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="box-label">{item.label}</div>

                        <div>
                          <i>
                            <b>{`(${item.time})`}</b>
                          </i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class="timeline-container">
                <center>
                  <div className="timeline-container">
                    <div
                      className="cage24-timeline-line"
                      style={{
                        background: cage24ColorData
                          ? `linear-gradient(to right, ${cage24ColorData})`
                          : "white",
                      }}
                      onMouseOver={(e) => handleCageHover(e, currentPosition)}
                    ></div>
                    {hoveredCage24Event && (
                      <div className="cage24popup">
                        <pre>{hoveredCage24Event}</pre>
                      </div>
                    )}
                  </div>
                </center>
                <div className="timeline-labels">
                  {timelineLabels.map((label, index) => (
                    <div key={index} className="timeline-label">
                      {label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card-grid-main">
            <div className="card-grid">
              {cardDetails.map((card, index) => (
                <div key={index} className="card-box">
                  <div className="card-details">
                    <div className="card-align">
                      <div className="analytics-card-column">
                        {/* Check if card.type is 'combined' */}
                        {card.type === "combined" ? (
                          <div className="combined-card-details">
                            {card.text.map((label, idx) => (
                              <div key={idx} className="combined-card-count">
                                <p className="card-text">
                                  {label.charAt(0).toUpperCase() +
                                    label.slice(1)}
                                </p>
                                <span className="card-count">
                                  {card.counts[idx]}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <>
                            <p className="card-text">{card.text}</p>
                            <span className="card-count">{card.count}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="card-graph-analytics">
            <span className="analytics-graph-title">Defects Graph</span>
            <BarChart data={sortedBarData} />
          </div>
          <div className="card-graph-analytics">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span className="analytics-graph-title">Defects Graph</span>
              <select value={selectedShift} onChange={handleShiftChange}>
                <option value="shift1">Shift 1</option>
                <option value="shift2">Shift 2</option>
                <option value="shift3">Shift 3</option>
              </select>
            </div>

            <div
              className="chart-container"
              style={{ position: "relative", height: "100%" }}
            >
              {hasData ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="line"
                    height="100%" // Ensure height is responsive within the container
                    style={{ overflowY: "hidden", width: "100%" }} // Ensure width adjusts to the container
                  />
                  <div
                    id="custom-legend"
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                    dangerouslySetInnerHTML={{ __html: customLegend }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    color: "#999",
                    width: "100%",
                  }}
                >
                  No Data
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  apiData: state.analyticsJsonData.data,
  objectData: state.data.data,
  nocableData: state.nocableData.data,
  apiDataLoading: state.analyticsJsonData.loading,
  objectDataLoading: state.data.loading,
  nocableDataLoading: state.nocableData.loading,
});

const mapDispatchToProps = {
  fetchAnalyticsData,
  fetchData,
  fetchDataRequest,
  fetchAnalyticsDataRequest,
  fetchNocableData,
  fetchAnalyticsDataSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

// // <div className="analycics-card-container2">
// //   <div className="analytics-graph-card">
// //     <span className="analytics-graph-title">
// //       Avg Repair Time Graph Per Shift
// //     </span>
// //     <ReactApexChart
// //       options={graphData.options}
// //       series={graphData.series}
// //       type="bar"
// //       height={300}
// //     />
// //   </div>
// //   <div className="overview-graph-card">
// //     <span className="overview-graph-title">Downtime Analysis</span>
// //     <div className="analytics-list-container">
// //       {typeof percentageAiTime === "undefined" ||
// //       isNaN(percentageAiTime) ||
// //       typeof percentageStopTime === "undefined" ||
// //       isNaN(percentageStopTime) ||
// //       (percentageAiTime === 0 && percentageStopTime === 0) ? (
// //         <div className="no-data">
// //           <img src={noData} alt="" width={200} />
// //           <span>No Data Found!</span>
// //         </div>
// //       ) : (
// //         <div className="analytics-align">
// //           <div className="analytics-card-pie-stats">
// //             <div className="analytics-row-container">
// //               <div className="analytics-dot" id="color1"></div>
// //               <span className="para">
// //                 RST Stopped by AI ({percentageAiTime}%)
// //               </span>
// //             </div>
// //             <div className="analytics-row-container">
// //               <div className="analytics-dot" id="color2"></div>
// //               <span className="para">
// //                 RST Stopped by Operator ({percentageStopTime}%)
// //               </span>
// //             </div>
// //           </div>
// //           <div className="analytics-pieChart">
// //             <Chart
// //               chartType="PieChart"
// //               data={pieData}
// //               options={options}
// //               width={"100%"}
// //               height={"300px"}
// //             />
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   </div>
// // </div>;

// import React, { useState } from "react";
// import ReactApexChart from "react-apexcharts";

// const App = () => {
//   const [selectedShift, setSelectedShift] = useState("shift1");

//   return (
//     <div className="card-graph-analytics">
//       <span className="analytics-graph-title">Defects Graph</span>
//       <div style={{ marginBottom: "20px" }}>
//         <label>Select Shift: </label>

//     </div>
//   );
// };

// export default App;
