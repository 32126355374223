export const setUserDetailsInLocalStorage = (userDetails) => {
  
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  };
  export const setUserDetailsInSessionStorage = (userDetails) => {
  
    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
  };
export  const getUserDetailsFromLocalStorage = () => {
    const storedUserDetails = localStorage.getItem('userDetails');
    return storedUserDetails ? JSON.parse(storedUserDetails) : null;
  };
  