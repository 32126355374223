// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-bar {
  border-radius: 5px 5px 0 0; /* Apply to top-left and top-right corners */
}
`, "",{"version":3,"sources":["webpack://./src/analytics/main/BarChart.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B,EAAE,4CAA4C;AAC1E","sourcesContent":[".apexcharts-bar {\r\n  border-radius: 5px 5px 0 0; /* Apply to top-left and top-right corners */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
