import { React, useState } from "react";
import Navbar from "./navbar/Navbar";
import Main from "./main/Main";
import "./Analytics.css";
import Sidebar from "../sidebar/Sidebar";
const Analytics = () => {
  const [selectedRST, setSelectedRST] = useState(null);
  return (
    <div className="App">
      <Sidebar />
      <div className="mainCss">
        <div className="navbar-container">
          <Navbar setSelectedRST={setSelectedRST} />
          <Main selectedRST={selectedRST} />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
