import React, { useState, useEffect } from "react";
import machnienr from "../../images/machnienr.svg";
import bulb from "../../images/bulb.svg";
import defect from "../../images/defect.svg";
import time from "../../images/time.svg";
import "./overviewMain.css";
import ReactApexChart from "react-apexcharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import { fetchData } from "../../redux/actions";
import { fetchJsonData } from "../../redux/actions";
import noData from "../../images/no-records-found.png";
import { Chart } from "react-google-charts";
const Main = ({ tableData, fetchData, fetchJsonData, jsonData }) => {
  const [refresh, setRefresh] = useState(new Date());

  useEffect(() => {
    fetchData();
    fetchJsonData();
    const intervalId = setInterval(() => {
      setRefresh(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [fetchData, fetchJsonData, refresh]);

  const targetTimeArray = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "23:59",
  ];

  const timeCounts = {};

  const filteredCount = tableData.filter(
    (item) =>
      item.time !== undefined &&
      item.time !== null &&
      item.time !== ""
      // && item.url.toLowerCase().endsWith(".jpg")
  );
  filteredCount.forEach((entry) => {
    const formattedTime = formatTime(entry.time);
    timeCounts[formattedTime] = (timeCounts[formattedTime] || 0) + 1;
  });

  console.log(tableData);
  function formatTime(time) {
    if (time) {
      const hour = parseInt(time.split(":")[0]);
      const formattedHour = hour.toString().padStart(2, "0");
      return `${formattedHour}:00`;
    }
    return "N/A";
  }
  // Create the count array based on the specified time array
  const countArray = targetTimeArray.map(
    (targetTime) => timeCounts[targetTime] || 0
  );

  // Calculate total count
  const totalCount = countArray.reduce((sum, count) => sum + count, 0);

  // Create the final object
  const graph = { time: targetTimeArray, count: countArray, total: totalCount };

  const totalDefectCount = Math.max(...graph.count);

  const machineCount = Object.values(jsonData).filter(
    (item) => item.machine === 1
  ).length;

  const formattedMachineCount = machineCount.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const aiCount = Object.values(jsonData).filter(
    (item) => item.ai === 1
  ).length;

  const formattedAiCount = aiCount.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const formattedDefectCount = graph.total.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  function calculateTimeDifference(starttime, endtime) {
    const startTime = new Date("2024-01-24T" + starttime + "Z");
    const endTime = new Date("2024-01-24T" + endtime + "Z");

    if (endTime < startTime) {
      endTime.setDate(endTime.getDate() + 1);
    }

    const timeDifference = endTime - startTime;

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  function calculateTotalTime(runTime) {
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    runTime.forEach((timePair) => {
      if (timePair.starttime && timePair.endtime) {
        const timeDifference = calculateTimeDifference(
          timePair.starttime,
          timePair.endtime
        );

        totalHours += timeDifference.hours;
        totalMinutes += timeDifference.minutes;
        totalSeconds += timeDifference.seconds;
      }
    });

    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds %= 60;

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return {
      hours: totalHours,
      minutes: totalMinutes,
      seconds: totalSeconds,
    };
  }

  const runTime = Object.values(jsonData).reduce(
    (accumulator, currentValue) =>
      accumulator.concat(
        currentValue.session
          .filter((event) => event.event === "runtime")
          .map((runtimeEvent) => ({
            starttime: runtimeEvent.starttime,
            endtime: runtimeEvent.endtime,
          }))
      ),
    []
  );

  const allRunTime = calculateTotalTime(runTime);
  const totalRunTime =
    allRunTime.hours * 60 + allRunTime.minutes + allRunTime.seconds / 60;

  const RepairTime = Object.values(jsonData).reduce(
    (accumulator, currentValue) =>
      accumulator.concat(
        currentValue.session
          .filter((event) => event.event === "repairtime")
          .map((runtimeEvent) => ({
            starttime: runtimeEvent.starttime,
            endtime: runtimeEvent.endtime,
          }))
      ),
    []
  );

  const allRepairTime = calculateTotalTime(RepairTime);

  const totalRepairTime =
    allRepairTime.hours * 60 +
    allRepairTime.minutes +
    allRepairTime.seconds / 60;

  const toggleOff = Object.values(jsonData).reduce(
    (accumulator, currentValue) =>
      accumulator.concat(
        currentValue.session
          .filter((event) => event.event === "toggleoff")
          .map((runtimeEvent) => ({
            starttime: runtimeEvent.starttime,
            endtime: runtimeEvent.endtime,
          }))
      ),
    []
  );

  const allToggleOff = toggleOff.filter((event) => {
    const startTime = new Date("2024-01-24T" + event.starttime + "Z");
    const endTime = new Date("2024-01-24T" + event.endtime + "Z");
    const timeDifference = (endTime - startTime) / (1000 * 60);
    return timeDifference > 20;
  });

  const allLossTime = calculateTotalTime(allToggleOff);

  const totalLossTime =
    allLossTime.hours * 60 + allLossTime.minutes + allLossTime.seconds / 60;

  const avgLossTime = totalLossTime - 20 * allToggleOff.length;

  function timeData(durationInSeconds) {
    if (durationInSeconds < 60) {
      return `${Math.floor(durationInSeconds)} sec`;
    } else if (durationInSeconds < 3600) {
      // Less than 1 hour, display as minutes and seconds
      const minutes = Math.floor(durationInSeconds / 60);
      const formattedMinutes = minutes.toString().padStart(2, "0");
      return `${formattedMinutes} mins`;
    } else {
      // 1 hour or more, display as hours and minutes
      const hours = Math.floor(durationInSeconds / 3600);
      const remainingSeconds = durationInSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      const main = Math.floor((minutes / 60) * 10);
      const formattedMinutes = main.toString();
      const formattedHours = hours;
      return `${formattedHours}.${formattedMinutes} hrs`;
    }
  }
  const TimeFormat = timeData(avgLossTime * 60);

  const totalMinutes = totalRunTime + totalRepairTime + avgLossTime;

  const percentageRunTime = Math.round((totalRunTime / totalMinutes) * 100);
  const percentageRepairTime = Math.round(
    (totalRepairTime / totalMinutes) * 100
  );
  const percentageLossTime = 100 - percentageRunTime - percentageRepairTime;
  const data = [
    ["Task", "Percentage"],
    [`Machine Runtime (${percentageRunTime}%)`, percentageRunTime],
    [`Defect Repair time(${percentageRepairTime}%)`, percentageRepairTime],
    [`Loss time(${percentageLossTime}%)`, percentageLossTime],
  ];
  const options = {
    is3D: true,
    legend: {
      position: "none",
    },
    chartArea: {
      width: "80%", // Set the width to 100%
      height: "80%", // Set the height to 100%
    },
    colors: ["#AAC5B9", "#A0A6D5", "#E2C6AB"],
  };

  // Generate an array of time values in 24-hour format with a 2-hour interval
  // Generate an array of time values in 24-hour format with a 2-hour interval
  console.log(...graph.count);
  // Use the generated time array in the x-axis categories
  const chartData = {
    series: [
      {
        name: "Defect Count",
        data: [...graph.count],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.7,
            opacityFrom: 0.9,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        categories: [
          "0hr",
          "1hr",
          "2hr",
          "3hr",
          "4hr",
          "5hr",
          "6hr",
          "7hr",
          "8hr",
          "9hr",
          "10hr",
          "11hr",
          "12hr",
          "13hr",
          "14hr",
          "15hr",
          "16hr",
          "17hr",
          "18hr",
          "19hr",
          "20hr",
          "21hr",
          "22hr",
          "23hr",
          "24hr",
        ],
        tickAmount: 12,
        title: {
          text: "Time",
          style: {
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
        labels: {
          style: {
            fontSize: "8px",
            fontWeight: "600",
            fontFamily: "PlusJakartaSans",
          },
        },
      },
      yaxis: {
        min: 0,
        max: totalDefectCount <= 5 ? 5 : Math.ceil(totalDefectCount / 5) * 5,
        tickAmount: 5,
        title: {
          text: "Defect Count",
          style: {
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
        labels: {
          style: {
            fontSize: "8px",
            fontWeight: "600",
            fontFamily: "PlusJakartaSans",
          },
        },
        grid: {
          strokeDashArray: 10, // Adjust the dash length to control the grid line thickness
          stroke: "#FFF", // Adjust the color of the grid lines if needed
        },
      },
      tooltip: {
        x: {
          format: "HH:mm",
        },
        tickAmount: 12,
      },
      colors: ["#143F7A"],
      fill: {
        colors: ["#143F7A"],
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 100],
        },
      },
    },
  };

  const cardDetails = [
    {
      text: "Total Live RST's",
      count: formattedMachineCount,
      image: machnienr,
    },
    {
      text: "Total Live AI",
      count: formattedAiCount,
      image: bulb,
    },
    {
      text: "Total Defects",
      count: formattedDefectCount,
      image: defect,
    },
    // {
    //   text: "AI Not Operational",
    //   count: TimeFormat,
    //   image: time,
    // },
  ];

  const sortedTableData = filteredCount
    .filter((row) => row.time) // Remove entries without 'time' property
    .slice()
    .sort((a, b) => {
      const timeA = a.time.split(":").map(Number);
      const timeB = b.time.split(":").map(Number);

      // Compare hours
      if (timeA[0] !== timeB[0]) {
        return timeA[0] - timeB[0];
      }

      // If hours are equal, compare minutes
      return timeA[1] - timeB[1];
    })
    .reverse();
  const top5TableData = sortedTableData.slice(0, 5);

  return (
    <div className="overview-main">
      <div className="card-container">
        {cardDetails.map((card, index) => (
          <div className="overview-card">
            <div className="overview-card-details">
              <div className="card-align">
                <div className="card-column">
                  <span className="overview-card-text">{card.text}</span>
                  {index === 3 ? (
                    <div style={{ display: "inline-flex" }}>
                      <span className="overview-card-count">{card.count}</span>
                    </div>
                  ) : (
                    <span className="overview-card-count">{card.count}</span>
                  )}
                </div>
                <div className="card-image">
                  <img src={card.image} alt="" className="card-image"></img>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="overview-graph-card">
          <span className="overview-graph-title">Defects Graph</span>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height={300}
          />
        </div>
      </div>
      <div className="card-container-table">
        <div className="tablecard">
          <span className="overview-graph-title">Recent Defects</span>

          <TableContainer
            component={Paper}
            className="overview-table-container"
          >
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>No</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Time</b>
                  </TableCell>
                  <TableCell>
                    <b>Cage No</b>
                  </TableCell>
                  <TableCell>
                    <b>RST</b>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <b>Image</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {top5TableData.map(
                  (row, index) =>
                    row.date && ( // Check if 'date' property is present
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {new Date(row.date).toLocaleDateString("en-GB")}
                        </TableCell>
                        <TableCell>{row.time}</TableCell>
                        <TableCell>{row.cage}</TableCell>
                        <TableCell>{row.rst}</TableCell>
                        <TableCell>
                          <img src={row.images[0]?.url} alt="" width={350} height={70} />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tableData: state.data.data,
  jsonData: state.jsonData.data,
});

const mapDispatchToProps = {
  fetchData,
  fetchJsonData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
