import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./NoPopup.css"

function DeletePopup({ showNoPopup, toggleNoPopup,handleDelete }) {
    const handleCancel = () => {
        toggleNoPopup();
      };
  return (
    <div
      className={`popup-container ${
        showNoPopup ? "show" : ""
      } no-popup-container`}
    >
      <div className="no-circle-container">
        <div className="no-circle">
          <RxCross2 className="cross-icon" />
        </div>
      </div>
      <div className="popup-title no-popup-title">False Defect!</div>
      <hr class="no-title-line"></hr>
      <div className="no-popup-container-in">
        <span>Are you sure this is not a defect?</span>
        <span>Click Proceed to train AI</span>
        <div className="no-popup-button-container">
          <div className="no-popup-button-container-in">
            <button className="popup-btn no-popup-btn" onClick={handleCancel}>
              Cancel
            </button>
            <button className="popup-btn no-popup-btn" onClick={handleDelete}>
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;
