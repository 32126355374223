import {React, useState} from 'react'
import Navbar from './navbar/Navbar'
import './Report.css'
import Main from "./main/Main"
import Sidebar from '../sidebar/Sidebar'
const Report = () => {
  const [selectedRST, setSelectedRST] = useState(null);

  return (
    <div className="App">
      <Sidebar />
    <div className="mainCss">
    <div className="reportpage-right-container">
      <Navbar setSelectedRST={setSelectedRST} />
      <Main selectedRST={selectedRST} />
    </div>
    </div>
    </div>
  );
}

export default Report
