import React, { useState } from "react";
import "./Login.css";
import loginLeftImg from "../images/login-left-img.png";
import { useNavigate, Link } from "react-router-dom";
import { fetchLogin } from "./../redux/actions";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = ({ fetchLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(null);

  const loginHandler = async () => {
    try {
      const loggedIn = await fetchLogin(username, password);

      if (loggedIn) {
        setTimeout(() => {
          navigate("/overview");
        }, 500);

        toast.success("Welcome");
      } else {
        navigate("/");
        toast.error("Login failed. Please check your credentials.");
        throw new Error("Login failed");
      }
    } catch (error) {
      toast.error("Login failed,Please Try After Sometime....");
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="login-left">
        <img className="login-left-img" src={loginLeftImg} alt="" />
      </div>
      <div className="login-right">
        <div className="login-box">
          <div className="title-div">
            <span className="sign-in title">Sign In</span>
            <p className="subtitle">Please enter your details</p>
          </div>
          <div className="username-container">
            <label>Username</label>
            <input
              className="username textfield"
              type="text"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="password-container">
            <label>Password</label>
            <input
              className="password textfield"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="forgot-container">
            <div className="remember-me-container">
              <input
                type="checkbox"
                className="forgot-checkbox"
                name="subscribe"
                id="subscribe"
              />
              <span> Remember Me</span>
            </div>
            <div>
              <span className="forget">Forget Password?</span>
            </div>
          </div>
          <button className="sign-in-btn" onClick={loginHandler}>
            Sign In
          </button>
          <div>
            <span>Don't have an account? </span>
            <Link to="" className="signup">
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  fetchLogin,
};

export default connect(null, mapDispatchToProps)(Login);
