// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  .reportpage-right-container{
    flex: 1 1;
    width: 100%;
    max-width:100%;
    max-height: 100vh; /* Set a maximum height */
    overflow:none
    
  }

  .App{
    overflow: hidden;
  }`, "",{"version":3,"sources":["webpack://./src/report/Report.css"],"names":[],"mappings":";;EAEE;IACE,SAAO;IACP,WAAW;IACX,cAAc;IACd,iBAAiB,EAAE,yBAAyB;IAC5C;;EAEF;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":["\r\n\r\n  .reportpage-right-container{\r\n    flex: 1;\r\n    width: 100%;\r\n    max-width:100%;\r\n    max-height: 100vh; /* Set a maximum height */\r\n    overflow:none\r\n    \r\n  }\r\n\r\n  .App{\r\n    overflow: hidden;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
