import { jsPDF } from "jspdf";
import {
  quanticLogo,
  quantPdfFooter,
  quant_viz_header,
  quant_report_footer,
} from "./QuantVizLogo";
import "jspdf-autotable";

export const printPdf = async (
  downloadRstDefectsEntries,
  base64ImgArray,
  shift,
  selectedRST
) => {
  console.log(downloadRstDefectsEntries);
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const imageWidth = pageWidth * 1; // Adjust the width as needed
  const imageHeight = 45; // Adjust the height as needed
  const tableImageWidth = 40;
  const tableImageHeight = (tableImageWidth / 4) * 1; // Adjust the height for the images in the table

  // Map the base64ImgArray to associate each image with its index
  // Get the current date and time
  const currentDate = new Date();

  // Format current date in YYYY_MM_DD for the "generated_at" part
  const generatedAtDate = currentDate
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, "_"); // Format: YYYY_MM_DD

  // Format current time in HH_MM_SS_AM/PM
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const currentTimeString = `${
    hours % 12 || 12
  }_${minutes}_${seconds}_${amOrPm}`;

  // Generate the dynamic file name
  const fileName = `Sterlite_Report_${generatedAtDate}_generated_at_${currentTimeString}.pdf`;

  // Initialize the Y coordinate
  const fontSize = 16; // Set the font size for the title
  const x = 10; // Adjust the horizontal position
  let y = 10; // Adjust the vertical position

  // Add the images associated with the current row index
  doc.addImage(quant_viz_header, "JPG", 0.1, 0.1, imageWidth, imageHeight);

  // Draw line below the image taking 90% of the horizontal space
  const imageLineHeight = 0.5; // Adjust the thickness of the line
  const imageLineWidth = pageWidth * 0.9; // Take 90% of the horizontal space
  // doc.line(x, y + imageHeight, x + imageLineWidth, y + imageHeight);

  // Draw title centered below the line
  const title = "QUANT VIZ-QUALITY REPORT";
  doc.setFontSize(fontSize);
  const titleWidth =
    (doc.getStringUnitWidth(title) * fontSize) / doc.internal.scaleFactor;
  const titleX = (pageWidth - titleWidth) / 2; // Center-align the title
  const titleY = y + imageHeight - 9; // Adjust the distance between the image and title
  doc.text(title, titleX, titleY);

  // Draw line below the title
  const titleLineY = titleY + 4; // Adjust the distance below the title
  const titleLineWidth = titleWidth; // Line width equals the title width
  doc.line(titleX, titleLineY, titleX + titleLineWidth, titleLineY);

  // Add present day's date centered below the title
  const smallFontSize = 14;
  const smallerFontSize = 12; // Set the smaller font size
  const dateOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const selectedRSTText = `${selectedRST}`;
  const rstWidth =
    (doc.getStringUnitWidth(selectedRSTText) * smallerFontSize) /
    doc.internal.scaleFactor;
  const rstX = (pageWidth - rstWidth) / 2; // Center-align the date below the title
  const rstY = titleLineY + 8; // Adjust the distance below the title line

  doc.setFontSize(smallFontSize);
  doc.text(selectedRSTText, rstX, rstY);

  const presentDate = `(${downloadRstDefectsEntries[0][0]}-${shift})`;
  const dateWidth =
    (doc.getStringUnitWidth(presentDate) * smallerFontSize) /
    doc.internal.scaleFactor;
  const dateX = (pageWidth - dateWidth) / 2; // Center-align the date below the title
  const dateY = rstY + 8; // Adjust the distance below the title line

  doc.setFontSize(smallerFontSize);
  doc.text(presentDate, dateX, dateY);

  // Push the table below
  y = dateY + 8; // Update the Y coordinate

  // Define the header separately
  const header = ["Date", "Time", "Cage", "Defect", "Image      "];

  // Draw the table on the current page
  doc.autoTable({
    head: [header],
    body: downloadRstDefectsEntries,
    startY: y, // Start the table below the date

    styles: {
      fontSize: 12,
      zIndex: 9, // Make sure text is above images
    },
    theme: "grid",
    headStyles: {
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
      fillColor: [18, 141, 189],
      halign: "center",
      valign: "middle",
      4: {
        cellWidth: 40,
        halign: "left",
        cellPadding: { top: 2, right: 6, bottom: 2, left: 0 },
      },
    },
    bodyStyles: {
      fillColor: [255, 255, 255],
      cellPadding: { top: 6, right: 2, bottom: 6, left: 2 },
      halign: "left",
      valign: "middle",
    },
    columnStyles: {
      0: { halign: "center" },
      1: { halign: "center" },
      2: { halign: "center" },
      3: {
        cellWidth: 35,
        halign: "center",
        cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
      },
      4: {
        cellWidth: 50,
        halign: "center",
        cellPadding: { top: 2, right: 2, bottom: 2, left: 0 },
      },
    },
    didDrawCell: function (data) {
      if (data.column.index === 4 && data.cell.section === "body") {
        const cellPaddingX = 5;
        const cellPaddingY = 3;
        // data.row.height = 15;
        var td = data.cell.raw;
        var dim = data.cell.height - data.cell.padding("vertical");
        var x = data.cell.x + cellPaddingX;
        var y = data.cell.y + cellPaddingY;
        doc.setFillColor(255, 255, 255);

        const rowIndex = data.row.index;
        doc.addImage(
          base64ImgArray[rowIndex],
          "JPG",
          x,
          y,
          tableImageWidth,
          tableImageHeight
        );
      }
    },
  });

  // Add the footer image on the last page
  const totalPages = doc.internal.getNumberOfPages();
  doc.setPage(totalPages); // Set the active page to the last page
  doc.addImage(
    quant_report_footer,
    "JPG",
    0,
    pageHeight - 45,
    pageWidth,
    imageHeight
  );

  doc.save(fileName);
};

// export const printPdf = async (
//   downloadRstDefectsEntries,
//   base64ImgArray,
//   shift,
//   selectedRST
// ) => {
//   const doc = new jsPDF();
//   const pageWidth = doc.internal.pageSize.getWidth();
//   const pageHeight = doc.internal.pageSize.getHeight();
//   const imageWidth = pageWidth * 1; // Adjust the width as needed
//   const imageHeight = 45; // Adjust the height as needed
//   const tableImageWidth = 40;
//   const tableImageHeight = (tableImageWidth / 4) * 1; // Adjust the height for the images in the table

//   // Get the current date and time
//   const currentDate = new Date();
//   // Format current date in YYYY_MM_DD for the "generated_at" part
//   const generatedAtDate = currentDate
//     .toISOString()
//     .slice(0, 10)
//     .replace(/-/g, "_"); // Format: YYYY_MM_DD
//   // Format current time in HH_MM_SS_AM/PM
//   const hours = currentDate.getHours();
//   const minutes = currentDate.getMinutes();
//   const seconds = currentDate.getSeconds();
//   const amOrPm = hours >= 12 ? "PM" : "AM";
//   const currentTimeString = `${
//     hours % 12 || 12
//   }_${minutes}_${seconds}_${amOrPm}`;
//   // Generate the dynamic file name
//   const fileName = `Sterlite_Report_${generatedAtDate}_generated_at_${currentTimeString}.pdf`;

//   // Add the images associated with the current row index
//   const drawHeader = () => {
//     doc.addImage(quant_viz_header, "JPG", 0.1, 0.1, imageWidth, imageHeight);
//   };

//   // Initialize the Y coordinate
//   let y = 10; // Adjust the vertical position

//   // Draw title centered below the line
//   const title = "QUANT VIZ-QUALITY REPORT";
//   const fontSize = 16; // Set the font size for the title
//   doc.setFontSize(fontSize);
//   const titleWidth =
//     (doc.getStringUnitWidth(title) * fontSize) / doc.internal.scaleFactor;
//   const titleX = (pageWidth - titleWidth) / 2; // Center-align the title
//   const titleY = y + imageHeight -5; // Adjust the distance between the image and title
//   doc.text(title, titleX, titleY);

//   // Add present day's date centered below the title
//   const smallFontSize = 14;
//   const smallerFontSize = 12; // Set the smaller font size
//   const selectedRSTText = `(${selectedRST})`;
//   const rstWidth =
//     (doc.getStringUnitWidth(selectedRSTText) * smallerFontSize) /
//     doc.internal.scaleFactor;
//   const rstX = (pageWidth - rstWidth) / 2; // Center-align the date below the title
//   const rstY = titleY + 8; // Adjust the distance below the title line
//   doc.setFontSize(smallerFontSize);
//   doc.text(selectedRSTText, rstX, rstY);

//   const presentDate = `(${downloadRstDefectsEntries[0][0]}-${shift})`;
//   const dateWidth =
//     (doc.getStringUnitWidth(presentDate) * smallerFontSize) /
//     doc.internal.scaleFactor;
//   const dateX = (pageWidth - dateWidth) / 2; // Center-align the date below the title
//   const dateY = rstY + 8; // Adjust the distance below the title line
//   doc.setFontSize(smallerFontSize);
//   doc.text(presentDate, dateX, dateY);

//   // Push the table below
//   y = dateY + 8; // Update the Y coordinate

//   // Define the header separately
//   const header = ["Date", "Time", "Cage", "Defect Type", "Image      "];

//   // Draw the table on the current page
//   const drawTable = (start, end) => {
//     doc.autoTable({
//       head: [header],
//       body: downloadRstDefectsEntries.slice(start, end),
//       startY: y, // Start the table below the date
//       styles: {
//         fontSize: 12,
//       },
//       theme: "grid",
//       headStyles: {
//         cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
//         fillColor: [18, 141, 189],
//         halign: "center",
//         valign: "middle",
//         4: {
//           cellWidth: 40,
//           halign: "left",
//           cellPadding: { top: 2, right: 6, bottom: 2, left: 0 },
//         },
//       },
//       bodyStyles: {
//         fillColor: [255, 255, 255],
//         cellPadding: { top: 6, right: 2, bottom: 6, left: 2 },
//         halign: "left",
//         valign: "middle",
//       },
//       columnStyles: {
//         0: { halign: "center" },
//         1: { halign: "center" },
//         2: { halign: "center" },
//         3: {
//           cellWidth: 35,
//           halign: "center",
//           cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
//         },
//         4: {
//           cellWidth: 50,
//           halign: "center",
//           cellPadding: { top: 2, right: 2, bottom: 2, left: 0 },
//         },
//       },
//       didDrawCell: function (data) {
//         if (data.column.index === 4 && data.cell.section === "body") {
//           const cellPaddingX = 5;
//           const cellPaddingY = 3;
//           var td = data.cell.raw;
//           var dim = data.cell.height - data.cell.padding("vertical");
//           var x = data.cell.x + cellPaddingX;
//           var y = data.cell.y + cellPaddingY;
//           doc.setFillColor(255, 255, 255);
//           const rowIndex = data.row.index;
//           doc.addImage(
//             base64ImgArray[rowIndex],
//             "JPG",
//             x,
//             y,
//             tableImageWidth,
//             tableImageHeight
//           );
//         }
//       },
//     });
//   };

//   // Check if there's enough space for the table on one page
//   const totalRows = downloadRstDefectsEntries.length;
//   const availableSpace = pageHeight - y - 20; // Leave some space for footer and margins
//   if (totalRows * 15 > availableSpace) {
//     // If the table exceeds the available space, split it into multiple pages
//     let startRowIndex = 0;
//     let endRowIndex = 0;
//     let pageNumber = 1;

//     while (endRowIndex < totalRows) {
//       endRowIndex = startRowIndex + Math.floor(availableSpace / 15); // Assuming 15 is the approximate height of each row
//       if (endRowIndex > totalRows) {
//         endRowIndex = totalRows;
//       }
//       if (pageNumber === 1) {
//         drawHeader();
//       }
//       drawTable(startRowIndex, endRowIndex);
//       pageNumber++;
//       if (endRowIndex < totalRows) {
//         doc.addPage();
//       }
//       startRowIndex = endRowIndex;
//     }
//   } else {
//     // If the table fits on one page, draw it directly
//     drawHeader();
//     drawTable(0, totalRows);
//   }

//   // Add the footer image on the last page
//   const totalPages = doc.internal.getNumberOfPages();
//   doc.setPage(totalPages);
//   doc.addImage(
//     quant_report_footer,
//     "JPG",
//     0,
//     pageHeight - 45,
//     pageWidth,
//     imageHeight
//   );

//   doc.save(fileName);
// };
