import React from "react";
import Main from "./main/Main";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import "../addDrum/AddDrum.css";

const AddDrum = () => {
  return (
    <div className="App">
      <Sidebar />
      <div className="mainCss">
        <div className="navbar-container">
          <Navbar />
        </div>
        <Main />
      </div>
    </div>
  );
};

export default AddDrum;
