import React, { useEffect, useState } from "react";
import "./DownloadPopup.css";
import { FaCheck } from "react-icons/fa";
import { RiArrowDownSFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { downloadExcelFile } from "../../redux/actions";
import { Checkbox } from "@mui/material";
import { MdClose } from "react-icons/md";
// import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

function DownloadPopup({
  defects,
  clientId,
  showDownloadPopup,
  toggleDownloadPopup,
  selectedDate,

}) {
  console.log(defects);
  const dispatch = useDispatch();
  const handleRSTChange = (event) => {
    setSelectedRst(event.target.value);
  };

  const [dateRangeText, setDateRangeText] = useState("From - To");
  const [selectedRst, setSelectedRst] = useState('RST5');
  const [calenderOpen, setCalenderOpen] = useState(false);
  // const [selectedShifts, setSelectedShifts] = useState([
  //   "shift1",
  //   "shift2",
  //   "shift3",
  // ]);
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  const [calenderDate, setCalenderDate] = useState([
    {
      startDate: selectedDate
        ? new Date(selectedDate).toLocaleString("en-US", options)
        : new Date().toLocaleString("en-US", options),
      endDate: selectedDate
        ? new Date(selectedDate).toLocaleString("en-US", options)
        : new Date().toLocaleString("en-US", options),
      key: "selection",
    },
  ]);
  const [calendarVisible, setCalendarVisible] = useState(false);
  // const [options, setOptions] = useState(['Patch', 'Dent', 'Others']);
  console.log(options);


  const handleCancel = () => {
    // Close the popup
    toggleDownloadPopup();
    setCalenderOpen(false);
  };
  const handleCalendarClick = () => {
    setCalenderOpen(!calenderOpen);
  };
  useEffect(() => {
    if (calenderDate.length > 0) {
      const fromText = dayjs(calenderDate[0].startDate).format("MMM DD");
      const toText = dayjs(calenderDate[0].endDate).format("MMM DD");
      setDateRangeText(`${fromText} - ${toText}`);
    } else {
      setDateRangeText("From - To");
    }
  }, [calenderDate]);

  // const handleShiftButtonClick = (shift) => {
  //   // If the shift is already selected, remove it from the array
  //   if (selectedShifts.includes(shift)) {
  //     setSelectedShifts(
  //       selectedShifts.filter((selectedShift) => selectedShift !== shift)
  //     );
  //   } else {
  //     // If the shift is not selected, add it to the array
  //     setSelectedShifts([...selectedShifts, shift]);
  //   }
  // };
  // const downloadPDF = async () => {
  //   // Format the selected dates
  //   const startDateParam = dayjs(calenderDate[0]?.startDate).format(
  //     "YYYY-MM-DD"
  //   );
  //   const endDateParam = dayjs(calenderDate[0]?.endDate).format("YYYY-MM-DD");
  //   console.log(endDateParam);
  //   downloadExcelFile(
  //     startDateParam,
  //     endDateParam,
  //     selectedRst
  //     // selectedShifts,
  //   );
  //   toggleDownloadPopup();
  // };
  const downloadExcel = async () => {
    toggleDownloadPopup();
    const startDateParam = dayjs(calenderDate[0]?.startDate).format("YYYY-MM-DD");
    const endDateParam = dayjs(calenderDate[0]?.endDate).format("YYYY-MM-DD");

    // Construct the URL with query parameters
    const downloadUrl = `${window.location.origin}/download-excel?fromDate=${startDateParam}&toDate=${endDateParam}&rst=${selectedRst}`;

    // Open the download page in a new tab
    window.open(downloadUrl, '_blank');
};

  const handleOutsideCalenderClick = (event) => {
    if (
      calenderOpen &&
      !event.target.closest(".calender-div") &&
      !event.target.closest(".download-calender-container")
    ) {
      setCalenderOpen(false);
    }
  };
  useEffect(() => {
    if (calenderOpen) {
      document.addEventListener("click", handleOutsideCalenderClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideCalenderClick);
    };
  }, [calenderOpen]);
  return (
    <div className={`popup-container-excel ${showDownloadPopup ? "show" : ""}`}>
      <div className="popup-container-excel-in">
        <div className={`calender-div ${calenderOpen ? "" : "hidden"}`}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              const { startDate, endDate } = item.selection;

              // Ensure that the end date is within 7 days after the start date
              const maxEndDate = new Date(startDate);
              maxEndDate.setDate(startDate.getDate() + 13); // Set the maximum end date to be 7 days after the start date

              // If the selected end date is after the maximum allowed end date, adjust it to the maximum allowed end date
              const adjustedEndDate =
                endDate > maxEndDate ? maxEndDate : endDate;

              setCalenderDate([
                {
                  startDate,
                  endDate: adjustedEndDate,
                  key: "selection",
                },
              ]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={calenderDate}
            endDatePlaceholder={new Date()}
          />
        </div>
        <div className="download-popup-header">
          <span>Download</span>
          <button onClick={handleCancel} className="download-close-btn">
            <MdClose className="close-icon" />
          </button>
        </div>
        <div className="date-container">
          <label>Date</label>
          <div
            className="download-calender-container"
            onClick={handleCalendarClick}
          >
            <span>{dateRangeText}</span>
            <MdOutlineCalendarMonth />
          </div>
        </div>
        <div className="popup-rst-dropdown-container">
          <select
            className="popup-rst-dropdown"
            value={selectedRst}
            onChange={handleRSTChange}
          >
            <option value="RST5">RST5</option>
            <option value="RST6">RST6</option>
          </select>
        </div>
        <div className="download-bay-buttons-container"></div>
        <div className="popup-footer-button-container-in">
          <button className="popup-btn cancel" onClick={handleCancel}>
            Cancel
          </button>
          <button className="popup-btn download" onClick={downloadExcel}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

export default DownloadPopup;
