import React from 'react'
import Navbar from './navbar/Navbar'
import Main from './main/Main'
import Sidebar from '../sidebar/Sidebar'
import './Overview.css'
const Overview = () => {
  return (
    <div className="App">
      <Sidebar />
    <div className="mainCss">
    <div className="navbar-container">
      <Navbar />
      <Main/>
    </div>
    </div>
  </div>
  
  )
}

export default Overview
