// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-content{
    padding: 35px;
    background: var(--Theme-Colors-White, #fff);
    display: flex;
    width: 92%;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top:0;
    z-index: 50;
    height: 120px;

}

.overview-header{
    color: #000;
    font-family: PlusJakartaSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.nav-icon{
    margin-left: 30px;
}

.header{
    color: black;
    font-family: Tahoma;
    word-wrap: break-word;    
}
`, "",{"version":3,"sources":["webpack://./src/addDrum/navbar/overviewNavbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2CAA2C;IAC3C,aAAa;IACb,UAAU;IACV,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,KAAK;IACL,WAAW;IACX,aAAa;;AAEjB;;AAEA;IACI,WAAW;IACX,4BAA4B;IAC5B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".navbar-content{\r\n    padding: 35px;\r\n    background: var(--Theme-Colors-White, #fff);\r\n    display: flex;\r\n    width: 92%;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    position: fixed;\r\n    top:0;\r\n    z-index: 50;\r\n    height: 120px;\r\n\r\n}\r\n\r\n.overview-header{\r\n    color: #000;\r\n    font-family: PlusJakartaSans;\r\n    font-size: 24px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: normal;\r\n}\r\n.nav-icon{\r\n    margin-left: 30px;\r\n}\r\n\r\n.header{\r\n    color: black;\r\n    font-family: Tahoma;\r\n    word-wrap: break-word;    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
