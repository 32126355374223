// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container {
 
  flex: 1 1;
  width: 100%;
  max-width:100%;
  max-height: 100vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  }
  

  `, "",{"version":3,"sources":["webpack://./src/analytics/Analytics.css"],"names":[],"mappings":"AAAA;;EAEE,SAAO;EACP,WAAW;EACX,cAAc;EACd,iBAAiB,EAAE,yBAAyB;EAC5C,gBAAgB,EAAE,8BAA8B;EAChD","sourcesContent":[".navbar-container {\r\n \r\n  flex: 1;\r\n  width: 100%;\r\n  max-width:100%;\r\n  max-height: 100vh; /* Set a maximum height */\r\n  overflow-y: auto; /* Enable vertical scrolling */\r\n  }\r\n  \r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
