// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container {
  flex: 1 1;
  width: 100%;
  max-width:100%;
  max-height: 100vh; /* Set a maximum height */
  overflow-y: hidden; /* Enable vertical scrolling */
}

.App{
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.sidebarCss{
  width: 8%;
  min-width: 150px;
}

.mainCss{
  width: 92%;
  min-width: 1250px;
}


@media (max-width: 1001px) {
  .sidebarCss {
    width: 6%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Overview/Overview.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,WAAW;EACX,cAAc;EACd,iBAAiB,EAAE,yBAAyB;EAC5C,kBAAkB,EAAE,8BAA8B;AACpD;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;;AAGA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".navbar-container {\r\n  flex: 1;\r\n  width: 100%;\r\n  max-width:100%;\r\n  max-height: 100vh; /* Set a maximum height */\r\n  overflow-y: hidden; /* Enable vertical scrolling */\r\n}\r\n\r\n.App{\r\n  height: 100vh;\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.sidebarCss{\r\n  width: 8%;\r\n  min-width: 150px;\r\n}\r\n\r\n.mainCss{\r\n  width: 92%;\r\n  min-width: 1250px;\r\n}\r\n\r\n\r\n@media (max-width: 1001px) {\r\n  .sidebarCss {\r\n    width: 6%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
