// Navbar.js

import React from "react";
import "./overviewNavbar.css";
import { Link } from "react-router-dom";
import notification from "../../images/notification.svg";
import user from "../../images/user.svg";
import sterlitelogo from "../../images/sterlitelogo.png";

const Navbar = () => {
  return (
    <div className="navbar-content">
      <div>
        <span className="overview-header">Drums</span>
      </div>
      <div className="analytics-navbar-buttons">
        <button className="nav-button">
          <img
            src={notification}
            alt="Notification"
            className="nav-icon"
            width={21}
            height={21}
          />
        </button>
        <button className="nav-button">
          <img
            src={user}
            alt="User"
            className="nav-icon"
            width={18}
            height={18}
          />
        </button>
        <button className="nav-button">
          <img
            src={sterlitelogo}
            alt="Sterlite Logo"
            className="nav-icon"
            width={"50px"}
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
