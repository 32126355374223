import { useRef } from "react";
import ApexCharts from "react-apexcharts";
import "./BarChart.css";

const BarChart = ({ data }) => {
  const chartRef = useRef(null);

  // Extract text and count values from the data prop
  const timeSlots = data.map((item) => item.text);
  const countData = data.map((item) => item.count);

  const maxCount = countData.length > 0 ? Math.max(...countData) : 0;

  // Generate a color array where each bar gets a different color
  const colors = [
    "#DB7C4D",
    "#5EA2BF",
    "#DB7C4D",
    "#5EA2BF",
    "#DB7C4D",
    "#5EA2BF",
  ];
  const colorArray = colors.slice(0, timeSlots.length); // Ensure the color array matches the number of bars

 const options = {
   series: [
     {
       name: "Count",
       data: countData,
     },
   ],
   chart: {
     type: "bar",
     stacked: false,
   },
   xaxis: {
     categories: timeSlots,
     title: {
       text: "Defects",
       style: {
         fontSize: "14px",
         fontWeight: "400",
         color: "black",
         fontFamily: "PlusJakartaSans",
       },
     },
     labels: {
       style: {
         fontSize: "12px",
         fontWeight: "400",
         colors: ["black"],
         fontFamily: "PlusJakartaSans",
       },
     },
   },
   yaxis: {
     title: {
       text: "Count",
       style: {
         fontSize: "14px",
         fontWeight: "400",
         color: "black",
         fontFamily: "PlusJakartaSans",
       },
     },
     min: 0,
     max: maxCount + 3,
     tickAmount: Math.min(maxCount + 3, 5),
     forceNiceScale: true,
     labels: {
       style: {
         fontSize: "12px",
         fontWeight: "400",
         colors: ["black"],
         fontFamily: "PlusJakartaSans",
       },
       formatter: function (value) {
         return Math.floor(value);
       },
     },
   },
   fill: {
     opacity: 1,
   },
   legend: {
     show: false,
   },
   plotOptions: {
     bar: {
       columnWidth: "20%",
       borderRadius: 5,
       distributed: true,
     },
   },
   dataLabels: {
     enabled: true, // Enable data labels
     offsetY: 10, // Position the labels above the bars
     dropShadow: {
       enabled: true,
       top: 1,
       left: 1,
       blur: 1,
       opacity: 0.75,
     },
     style: {
       fontSize: "12px",
       fontWeight: "400",
       colors: ["black"], // Color of the data labels
     },
     formatter: function (val) {
       return val === 0 ? "" : Math.floor(val); // Hide labels for zero values
     },
   },
   colors: colorArray, // Use the color array for the bars
 };

  const series = [
    {
      name: "Count",
      data: countData,
    },
  ];

  return (
    <div id="chart">
      <ApexCharts
        ref={chartRef}
        options={options}
        series={series}
        type="bar"
        height={250}
      />
    </div>
  );
};

export default BarChart;
