import React, { useEffect } from 'react';
import { downloadExcelFile } from '../../redux/actions';
import { useLocation } from 'react-router-dom';
import downloadLoading from "../../images/download-filter.gif";
const DownloadExcel = () => {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fromDate = params.get('fromDate');
        const toDate = params.get('toDate');
        const rst = params.get('rst');

        // Trigger the download
        downloadExcelFile(fromDate, toDate, rst).then(() => {
         window.close(); // Optionally close the tab after download
        });
    }, [location]);

    return (
      <div
      style={{
        width: "100vw",
        height: "100vh",
        flexDirection:"column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap:"1rem",
      }}
    >
    <img width={"7%"} src={downloadLoading} />
      <div style={{fontWeight:"600", fontSize:"18px"}}>Downloading Please wait....</div>
    </div>
    );
};

export default DownloadExcel;