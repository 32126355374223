import React, { useEffect, useState, useMemo } from 'react';
import { fetchBase64ImgData } from '../../redux/actions';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { printPdf } from '../Download/GenerateTablePdf';
import downloadLoading from "../../images/download-filter.gif";

const formatEntriesDates = (dateString) => {
  if (dateString.includes("undefined")) {
    return "Invalid date format";
  }
  const [year, month, day] = dateString.split("-");
  if (!year || !month || !day) {
    return "Invalid date format";
  }
  return `${day}/${month}/${year}`;
};

const getDefaultBase64ImageUrl = (images) => {
  const defaultString = 'default';
  if (images.length === 1) {
    return images[0].url;
  }
  const image = images.find(img => img.camNo.includes(defaultString));
  return image ? image.base64ImageURL : images[0].base64ImageURL;
};

const processEntries = (base64ImgData, selectedRST, selectedShift,selectedAccuracy) => {
  // Sort base64ImgData based on time in descending order
  const sortedBase64ImgData = base64ImgData.slice().sort((a, b) => {
    const getTimeInSeconds = (time) => {
      return time.split(":").reduce((acc, part, i) => acc + part * Math.pow(60, 2 - i), 0);
    };

    return getTimeInSeconds(b.time) - getTimeInSeconds(a.time);
  });

  // Filter based on time existence
  let filteredBase64Entries = sortedBase64ImgData.filter(entry => entry.time && entry.time !== "");

  // Apply filtering based on selectedRST
  if (selectedRST) {
    filteredBase64Entries = filteredBase64Entries.filter(entry => entry.rst === selectedRST);
  }
  if (selectedAccuracy !==null) {
    filteredBase64Entries = filteredBase64Entries.filter((entry) => {
      const accuracy = parseFloat(entry.defectAccuracy);
      return (     
        (!selectedAccuracy || 
          (Number(selectedAccuracy) === 100 
            ? accuracy === 100.00 
            : accuracy >= Number(selectedAccuracy) && accuracy <= Number(selectedAccuracy) + 0.99)
        ) 
      );
    })
  }

  // Apply filtering based on selectedShift
  if (selectedShift) {
    const [shiftStart, shiftEnd] = selectedShift.split("-").map((time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    });

    filteredBase64Entries = filteredBase64Entries.filter((entry) => {
      const entryTimeInMinutes = entry.time.split(":").map(Number).reduce((acc, part, i) => acc + part * Math.pow(60, 1 - i), 0);

      return shiftStart <= shiftEnd
        ? entryTimeInMinutes >= shiftStart && entryTimeInMinutes <= shiftEnd
        : entryTimeInMinutes >= shiftStart || entryTimeInMinutes <= shiftEnd;
    });
  }

  // Map the filtered entries to the desired format
  return filteredBase64Entries.map((entry) => ({
    date: formatEntriesDates(entry.date),
    time: entry.time,
    cage: entry.cage,
    defect: `${entry.defectType} ${entry.defectAccuracy}%`,
    base64ImageURL: getDefaultBase64ImageUrl(entry?.images),
  }));
};

const DownloadPDF = ({ fetchBase64ImgData, base64ImgData, }) => {
  const location = useLocation();
  const [selectedRST, setSelectedRST] = useState("");
  const [downloadDefectsEntries, setDownloadDefectsEntries] = useState([]);
  const [base64ImgArray, setBase64ImgArray] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [selectedAccuracy, setSelectedAccuracy] = useState("");
  const [shift, setShift] = useState("ALL SHIFTS");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const date = params.get('date');
    setSelectedRST(params.get('rst'));
    setSelectedShift(params.get('selectedShift'));
    setSelectedAccuracy(params.get('selectedAccuracy'));
    fetchBase64ImgData(date);
  }, [location]);

  useEffect(() => {
    if (selectedShift) {
      if (selectedShift === "07:00-15:00") {
        setShift("SHIFT-1");
      } else if (selectedShift === "15:00-23:00") {
        setShift("SHIFT-2");
      } else if (selectedShift === "23:00-07:00") {
        setShift("SHIFT-3");
      }
    } else {
      setShift("ALL SHIFTS");
    }
  }, [selectedShift]);

  const entriesWithDefect = useMemo(() => {
    if (!base64ImgData) return [];
    return processEntries(base64ImgData, selectedRST, selectedShift,selectedAccuracy);
  }, [base64ImgData, selectedRST, selectedShift,selectedAccuracy]);
  useEffect(() => {
    if (entriesWithDefect.length === 0) return;

    setBase64ImgArray(entriesWithDefect.map((entry) => entry.base64ImageURL));
    setDownloadDefectsEntries(entriesWithDefect.map((entry) =>
      Object.values({
        date: entry.date,
        time: entry.time,
        cage: entry.cage,
        defect: entry.defect,
      })
    ));


    
    if (downloadDefectsEntries.length > 0 && base64ImgArray.length > 0) {
      printPdf(downloadDefectsEntries, base64ImgArray, shift, selectedRST).then(() => {
        window.close(); // Close the tab after download
      });;
    }
  }, [entriesWithDefect, shift, selectedRST, downloadDefectsEntries, base64ImgArray]);
  console.log(downloadDefectsEntries)

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        flexDirection:"column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap:"1rem",
      }}
    >
    <img width={"7%"} src={downloadLoading} />
      <div style={{fontWeight:"600", fontSize:"18px"}}>Downloading Please wait....</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  base64ImgData: state.base64ImgData.data,
  base64ImgDataLoading: state.base64ImgData.loading,
});

const mapDispatchToProps = {
  fetchBase64ImgData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPDF);