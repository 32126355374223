import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import app from "../images/app.svg";
import chart from "../images/chart.svg";
import report from "../images/report.svg";
import logout from "../images/logout.svg";
import logo from "../images/logo.png";
import drum from "../images/drum.svg";
import "./sidebar.css";
import { fetchLogout } from "../redux/actions";
import { connect } from "react-redux";

const Sidebar = ({ fetchLogout }) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    if (path === "/overview") {
      setActiveLink(0);
    } else if (path === "/analytics") {
      setActiveLink(1);
    } else if (path === "/report") {
      setActiveLink(2);
    } else if (path === "/add-drum") {
      setActiveLink(3);
    } else {
      setActiveLink(-1);
    }
  }, [location.pathname]);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  // const handleLogoutClick = () => {
  //   navigate('/');
  // };
  const handleLogoutClick = async () => {
    try {
      await fetchLogout();
      // navigate('/')
      localStorage.clear(); // Clear the session storage
      sessionStorage.clear();
      document.cookie =
        "token=; path=/; domain=.example.com; expires=" +
        new Date(0).toUTCString();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <div className="container-fluid sidebar-container">
      <div className="row sidebar-row">
        <div
          className="col-sm-auto sticky-top sidebar"
          style={{ backgroundColor: "#000" }}
        >
          <div className="text-center">
            <img src={logo} alt="" className="logo" />
            <hr className="hr" />
          </div>
          <div className="d-flex flex-sm-column flex-row align-items-center">
            <ul className="nav nav-flush flex-sm-column flex-row mb-auto mx-auto text-center justify-content-between w-100 align-items-center ul-gap">
              <li className="nav-item">
                <Link
                  to="/overview"
                  className={`nav-link py-3 px-2 link-og ${
                    activeLink === 0 ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick(0)}
                  title="Overview"
                >
                  <img src={app} alt="" />
                </Link>
              </li>
              <li>
                <Link
                  to="/analytics"
                  className={`nav-link py-3 px-2 link-og ${
                    activeLink === 1 ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick(1)}
                  title="Analytics"
                >
                  <img src={chart} alt="" />
                </Link>
              </li>
              <li>
                <Link
                  to="/report"
                  className={`nav-link py-3 px-2 link-og ${
                    activeLink === 2 ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick(2)}
                  title="Report"
                >
                  <img src={report} alt="" />
                </Link>
              </li>

              <li>
                <Link
                  to="/add-drum"
                  className={`nav-link py-3 px-2 link-og ${
                    activeLink === 3 ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick(3)}
                  title="Drum"
                >
                  <img src={drum} alt="" />
                </Link>
              </li>
            </ul>
          </div>
          <div
            className={`nav-link logout ${activeLink === 4 ? "active" : ""} ${
              window.innerWidth >= 1000 && window.innerWidth <= 1600
                ? "logout-bottom-center"
                : ""
            }`}
            onClick={handleLogoutClick}
            title="Logout"
          >
            <img src={logout} alt="" style={{ margin: "12px 5px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.data,
});

const mapDispatchToProps = {
  fetchLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
