import { combineReducers } from "redux";
const images = {
  data: [],
  loading: false,
  error: null,
};

const json = {
  data: [],
  loading: false,
  error: null,
};
const defects = {
  data: [],
  loading: false,
  error: null,
};
const analyticsData = {
  data: [],
  loading: false,
  error: null,
};

const nocableData = {
  data: [],
  loading: false,
  error: null,
};
const rstArray = {
  data: [],
  loading: false,
  error: null,
};
const base64Images = {
  data: [],
  loading: false,
  error: null,
};
const userDataObj = {
  data: [],
  loading: false,
  error: null,
  isAuthenticated: false,
  success: false,
};

const drums = {
  data: [],
  loading: false,
  error: null,
};

const drumDetails = {
  drums: [],
  loading: false,
  error: null,
};

const imagesReducer = (state = images, action) => {
  switch (action.type) {
    case "FETCH_DATA_REQUEST":
      return { ...state, loading: true, error: null, data: [] };
    case "FETCH_DATA_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_DATA_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const jsonReducer = (state = json, action) => {
  switch (action.type) {
    case "FETCH_JSON_DATA_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_JSON_DATA_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_JSON_DATA_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const analyticsReducer = (state = analyticsData, action) => {
  switch (action.type) {
    case "FETCH_ANALYTICS_DATA_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_ANALYTICS_DATA_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_ANALYTICS_DATA_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const nocableReducer = (state = nocableData, action) => {
  switch (action.type) {
    case "FETCH_NOCABLE_DATA_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_NOCABLE_DATA_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_NOCABLE_DATA_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const rstReducer = (state = rstArray, action) => {
  switch (action.type) {
    case "FETCH_RST_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_RST_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_RST_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const base64ImgDataReducer = (state = base64Images, action) => {
  switch (action.type) {
    case "FETCH_BASE64IMG_DATA_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_BASE64IMG_DATA_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_BASE64IMG_DATA_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const defectsReducer = (state = defects, action) => {
  switch (action.type) {
    case "DEFECT_FETCH_REQUEST":
      return { ...state, loading: true, error: null };
    case "DEFECT_FETCH_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "DEFECT_FETCH_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const updateDefectsReducer = (state = defects, action) => {
  switch (action.type) {
    case "DEFECT_UPDATE_REQUEST":
      return { ...state, loading: true, error: null };
    case "DEFECT_UPDATE_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "DEFECT_UPDATE_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const userReducer = (state = userDataObj, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { ...state, loading: true, error: null };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        success: action.payload.success,
        loading: false,
        data: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        success: action.payload.success,
        loading: false,
        error: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case "LOGOUT_REQUEST":
      return { ...state, loading: true, error: null };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case "LOGOUT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    default:
      return state;
  }
};

const addDrumReducer = (state = drums, action) => {
  switch (action.type) {
    case "DRUMS_ADD_REQUEST":
      return { ...state, loading: true, error: null };
    case "DRUMS_ADD_SUCCESS":
      return { ...state, loading: false };
    case "DRUMS_ADD_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const drumsFetchReducer = (state = drums, action) => {
  switch (action.type) {
    case "FETCH_DRUMS_REQUEST":
      return { ...state, loading: true, error: null, data: [] };
    case "FETCH_DRUMS_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    case "FETCH_DRUMS_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const drumDetailsReducer = (state = drumDetails, action) => {
  switch (action.type) {
    case "FETCH_DRUM_REQUEST":
    case "EDIT_DRUM_REQUEST":
    case "DELETE_DRUM_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_DRUM_SUCCESS":
      return {
        ...state,
        loading: false,
        selectedDrum: action.payload,
        error: null,
      };
    case "EDIT_DRUM_SUCCESS":
      return {
        ...state,
        loading: false,
        drums: state.drums.map((drum) =>
          drum._id === action.payload._id ? action.payload : drum
        ),
        error: null,
      };
    case "DELETE_DRUM_SUCCESS":
      return {
        ...state,
        loading: false,
        drums: state.drums.filter((drum) => drum._id !== action.payload),
        error: null,
      };
    case "FETCH_DRUM_FAILURE":
    case "EDIT_DRUM_FAILURE":
    case "DELETE_DRUM_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  data: imagesReducer,
  jsonData: jsonReducer,
  analyticsJsonData: analyticsReducer,
  rstData: rstReducer,
  base64ImgData: base64ImgDataReducer,
  userData: userReducer,
  nocableData: nocableReducer,
  defectsData: defectsReducer,
  updateDefectData: updateDefectsReducer,
  addDrum: addDrumReducer,
  drums: drumsFetchReducer,
  drumDetails: drumDetailsReducer,
});

export default rootReducer;
