import React, { useState, useEffect } from "react";
import "./YesPopup.css";
import { FaCheck } from "react-icons/fa";
import { RiArrowDownSFill } from "react-icons/ri";
import { fetchDefectData, updateDefect } from "../../redux/actions";
import { connect } from "react-redux";

function VerifyPopup({ showYesPopup, toggleYesPopup, handleVerifyAndRename,fetchDefectData,defects,updateDefect }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [customDefectType, setCustomDefectType] = useState('');
  const [options, setOptions] = useState(defects);

  useEffect(() => {
    // Load options from localStorage or set default options if not present
    const storedOptions =  Object.keys(defects)
    setOptions(sortOptions(storedOptions));
  }, [defects]);
  useEffect(()=>{
    fetchDefectData();
  },[])
  console.log("defects",defects)

  const sortOptions = (options) => {
    // Ensure "Others" is at the bottom
    return options.sort((a, b) => (a === "others" ? 1 : b === "others" ? -1 : 0));
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);

    if (option === 'others') {
      // If 'Others' is selected, show the input field
      setCustomDefectType('');
      setDropdownOpen(true); // Keep the dropdown open to display the input field
    } else {
      // Call the handleVerifyAndRename function with the selected option
      setCustomDefectType(option);
    }
  };

  const handleCustomDefectTypeChange = (event) => {
    // Allow only alphanumeric characters and spaces
    const value = event.target.value.replace(/[^A-Za-z0-9\s]/g, '');
    console.log(value)
    setCustomDefectType(value);
  };

  const handleVerifyAndRenameCustom = () => {
    // Call the handleVerifyAndRename function with the custom defect type
    handleVerifyAndRename(customDefectType);

    // Retrieve the existing defects array from localStorage
    let defectsArr = Object.keys(defects) || [];
    
    // Add the new value to the defects array
    if (!defectsArr.includes(customDefectType)) {
      updateDefect(customDefectType);
    }
    
    // Save the updated array back to localStorage
    localStorage.setItem("defects", JSON.stringify(defects));

    // Capitalize the first letter of customDefectType before adding to options array
    const capitalizedCustomDefectType = customDefectType.charAt(0).toUpperCase() + customDefectType.slice(1);
  
    // Add the custom defect type to the options array if it's not already included
    if (!options.includes(capitalizedCustomDefectType)) {
      const updatedOptions = [...options, capitalizedCustomDefectType];
      setOptions(sortOptions(updatedOptions));
      localStorage.setItem("options", JSON.stringify(sortOptions(updatedOptions)));
    }
  
    // Reset the customDefectType to empty string
    setCustomDefectType('');
  
    // Close the popup
    toggleYesPopup();
  };

  const handleCancel = () => {
    // Close the popup
    toggleYesPopup();
  };

  return (
    <div className={`popup-container ${showYesPopup ? "show" : ""}`}>
      <div className="yes-circle-container">
        <div className="yes-circle">
          <FaCheck className="check-icon" />
        </div>
      </div>
      <div className="popup-title">True Defect !</div>
      <hr className="yes-title-line" />
      <div className="popup-container-in">
        <span>Please select the type of defect</span>
        <div className="custom-dropdown">
          <div
            className="dropdown-trigger"
            onClick={() => setDropdownOpen(!isDropdownOpen)}
          >
            {selectedOption || "Select Defect"}
            <RiArrowDownSFill className="popup-down-arrow-icon" />
          </div>

          {isDropdownOpen && (
            <div className="dropdown-options">
              {options.map((option, index) => (
                <button
                  className="dropdown-option"
                  key={index}
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </button>
              ))}
              {selectedOption === "others" && (
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Enter custom defect type"
                  value={customDefectType}
                  onChange={handleCustomDefectTypeChange}
                />
              )}
            </div>
          )}
        </div>
        <span>Click Proceed to train AI</span>
        <div className="popup-button-container">
          <div className="popup-button-container-in">
            <button className="popup-btn" onClick={handleCancel}>
              Cancel
            </button>
            <button className="popup-btn" onClick={handleVerifyAndRenameCustom}>
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  defects: state.defectsData.data
});

const mapDispatchToProps = {
  fetchDefectData,
  updateDefect
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPopup);