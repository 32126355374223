import React, { useState, useEffect } from "react";
import "./addDrum.css";
import { connect } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Button, InputAdornment, colors } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import CircularProgress from "@mui/material/CircularProgress";
import empty from "../../images/empty.gif";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import close from "../../images/close.svg";
import deletePopup from "../../images/deletePopup.svg";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  fetchDrumsData,
  addDrums,
  fetchData,
  fetchDrum,
  editDrum,
  deleteDrum,
} from "../../redux/actions";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import dayjs from "dayjs";
import brandEdit from "../../images/brandEdit.svg";
import brandDelete from "../../images/brandDelete.svg";

import { Toaster, toast } from "react-hot-toast";
const StyledDatePicker = styled(DesktopDatePicker)`
  width: 180px; /* Adjust width as needed */
  height: 41px; /* Adjust height as needed */
  border-radius: 5px;
`;

const Main = ({
  fetchDrumsData,
  // drumsData,
  addDrums,
  fetchData,
  // tableData,
  tableDataLoading,
  fetchDrum,
  drumDetails,
  editDrum,
  deleteDrum,
}) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [refresh, setRefresh] = useState(new Date());
  const [drumsData, setDrumsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openUserPopup, setOpenUserPopup] = useState(false);
  const [selectedShift, setSelectedShift] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  const [formData, setFormData] = useState({
    machine_name: "",
    drum_number: "",
    date: dayjs().format("YYYY-MM-DD"),
    start_time: null,
    end_time: null,
    drum_length: "",
  });
  useEffect(() => {
    setDrumsData([]);
    setTableData([]);
    const fetchDataAndDrumsData = async () => {
      try {
        const formattedDate = selectedDate.format("YYYY-MM-DD");

        // Fetch drums data
        const drumsData = await fetchDrumsData(formattedDate);
        if (drumsData) {
          setDrumsData(drumsData);
        }

        // Fetch table data
        const tableData = await fetchData(formattedDate);
        if (tableData) {
          setTableData(tableData);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchDataAndDrumsData();

    const intervalId = setInterval(() => {
      setRefresh(new Date());
      fetchDataAndDrumsData();
    }, 200000);

    return () => clearInterval(intervalId);
  }, [fetchData, fetchDrumsData, selectedDate]);

  useEffect(() => {
    if (edit && drumDetails?.selectedDrum) {
      setFormData({
        machine_name: drumDetails.selectedDrum.machine_name || "",
        drum_number: drumDetails.selectedDrum.drum_number || "",
        date: drumDetails.selectedDrum.date || "",
        start_time: drumDetails.selectedDrum.start_time || "", // Leaving password empty for security reasons
        end_time: drumDetails.selectedDrum.end_time || "",
        drum_length: drumDetails.selectedDrum.drum_length || "",
      });
    }
  }, [edit, drumDetails]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  function createData(
    id,
    drum,
    drumlength,
    machine,
    shift,
    starttime,
    endtime,
    stop,
    start,
    Edit
  ) {
    return {
      id,
      drum,
      drumlength,
      machine,
      shift,
      starttime,
      endtime,
      stop,
      start,
      Edit,
    };
  }
  const columns = [
    {
      width: 100,
      label: "Drum No",
      dataKey: "drum",
    },
    {
      width: 100,
      label: "Drum Length",
      dataKey: "drumlength",
    },
    {
      width: 100,
      label: "Machine name",
      dataKey: "machine",
    },
    {
      width: 100,
      label: "Shift",
      dataKey: "shift",
    },
    {
      width: 100,
      label: "Start Time",
      dataKey: "starttime",
    },
    {
      width: 100,
      label: "End Time",
      dataKey: "endtime",
    },
    {
      width: 100,
      label: "Stop Count",
      dataKey: "stop",
    },
    {
      width: 100,
      label: "Unstopped Count",
      dataKey: "start",
    },
    {
      width: 80,
      label: "",
      dataKey: "Edit",
      numeric: true,
    },
  ];

const getCountInInterval = (json1, json2) => {
  let counts = []; // Initialize counts inside the function

  const toMilliseconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600000 + minutes * 60000 + seconds * 1000;
  };

  for (let i = json1.length - 1; i >= 0; i--) {
    const startTime = toMilliseconds(json1[i].start_time);
    const endTime = json1[i].end_time
      ? toMilliseconds(json1[i].end_time)
      : i > 0
      ? toMilliseconds(json1[i - 1].start_time)
      : null; // Corrected conditional assignment for endTime

    const machineName = json1[i].machine_name; // Get machine_name from json1

    let count = 0;
    if (endTime !== null) {
      json2.forEach((record) => {
        const recordTime = toMilliseconds(record.time);
        if (
          recordTime >= startTime &&
          recordTime < endTime &&
          record.rst === machineName
        ) {
          count++;
        }
      });
    } else {
      // Handle the last interval where there's no endTime (if needed)
      json2.forEach((record) => {
        const recordTime = toMilliseconds(record.time);
        if (recordTime >= startTime && record.rst === machineName) {
          count++;
        }
      });
    }

    counts.unshift(count);
  }

  return counts;
};

  const tableDataStop = tableData.filter(
    (item) => item.machineStatus === "STOP"
  );

  const tableDataStart = tableData.filter(
    (item) => item.machineStatus === "START"
  );

  const stopCount = getCountInInterval(drumsData, tableDataStop);
  const startCount = getCountInInterval(drumsData, tableDataStart);

  const transformedData =
    drumsData?.map((item, index) => {
      // Retrieve count from result array (use default 0 if index out of bounds)
      const stop = stopCount[index] || 0;
      const start = startCount[index] || 0;

      // Determine the shift based on the start_time
      const startTime = item.start_time || "00:00:00";
      let shift = "Shift3"; // Default to Shift3

      if (startTime >= "07:00:00" && startTime <= "14:59:59") {
        shift = "Shift1";
      } else if (startTime >= "15:00:00" && startTime <= "22:59:59") {
        shift = "Shift2";
      } else if (startTime >= "23:00:00" || startTime < "07:00:00") {
        shift = "Shift3";
      }

      // Remove the underscore from the drum_number if it exists
      const drumNumber = item.drum_number.replace(/_+$/, "");

      // Return the transformed data array
      return [
        drumNumber, // Use the modified drum number
        item.drum_length || "-",
        item.machine_name,
        shift, // Insert the shift after the machine name
        item.start_time || "-",
        item.end_time || "-",
        stop,
        start,
        item._id,
      ];
    }) || [];

  const handleShiftChange = (event) => {
    const selectedShift = event.target.value;
    setSelectedShift(selectedShift);
  };
  const handleMachineChange = (event) => {
    const selectedMachine = event.target.value;
    setSelectedMachine(selectedMachine);
  };

  const filteredData = transformedData.filter((data) => {
    const matchesShift = selectedShift === "" || data[3] === selectedShift;
    const matchesMachine =
      selectedMachine === "" || data[2] === selectedMachine;

    return matchesShift && matchesMachine;
  });
  const rows = filteredData.map((data, index) => createData(index, ...data));

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <TableRow
        sx={{
          height: "40px",
        }}
      >
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? "right" : "left"}
            style={{ width: column.width }}
            sx={{
              backgroundColor: "background.paper",
              height: "60px",
              fontSize: "13px", // Customize the font size
              fontFamily: "Plus Jakarta Sans",
              color: "rgba(39, 73, 122, 1)",
              fontWeight: 600,
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  const handleDeletePopup = async (userId) => {
    setUserId(userId);
    setOpenDelete(true);
  };
  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
  };
  const handleDelete = async () => {
    await deleteDrum(userId);
    setOpenDelete(false);

    const formattedDate = selectedDate.format("YYYY-MM-DD");

    const drumsData = await fetchDrumsData(formattedDate);
    if (drumsData) {
      setDrumsData(drumsData);
    }
    toast.success("User Deleted Sucessfully");
  };
  const handleEditUserClick = async (userId) => {
    setUserId(userId);
    await fetchDrum(userId);
    setEdit(true);

    setOpenUserPopup(true);
    //  navigate(`/editbrand/${trend}/${brandId}`);
  };
  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
            sx={{
              fontSize: "15px",
              fontFamily: "Plus Jakarta Sans",
              fontWeight: 400,
            }}
          >
            {column.dataKey === "Edit" ? (
              <div className="brand-buttons">
                <button
                  className="edit-button"
                  onClick={() => handleEditUserClick(row["Edit"])}
                >
                  <img
                    src={brandEdit}
                    alt={column.dataKey}
                    style={{ width: "24px", height: "24px" }}
                  />
                </button>
                <button
                  className="edit-button"
                  onClick={() => handleDeletePopup(row["Edit"])}
                >
                  <img
                    src={brandDelete}
                    alt={column.dataKey}
                    style={{ width: "24px", height: "24px" }}
                  />
                </button>
              </div>
            ) : (
              row[column.dataKey]
            )}{" "}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDate = (newValue) => {
    setFormData({
      ...formData,
      date: newValue.format("YYYY-MM-DD"),
    });
  };

  const handleTimeChange = (name, newValue) => {
    setFormData({
      ...formData,
      [name]: newValue.format("HH:mm:ss"),
    });
  };

  const handleCancel = () => {
    setFormData({
      machine_name: "",
      drum_number: "",
      date: dayjs().format("YYYY-MM-DD"),
      start_time: null,
      end_time: null,
      drum_length: null,
    });
    setOpenUserPopup(false);
  };

  const handleAdd = async () => {
    if (
      !formData.machine_name ||
      !formData.drum_number ||
      !formData.date ||
      !formData.start_time
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    console.log("formdata", formData);
    try {
      if (edit) {
        const response = await editDrum(userId, {
          machine_name: formData.machine_name,
          drum_number: formData.drum_number,
          date: formData.date,
          start_time: formData.start_time,
          end_time: formData.end_time,
          drum_length: formData.drum_length,
        });

        if (response.status) {
          const formattedDate = selectedDate.format("YYYY-MM-DD");
          const data = await fetchDrumsData(formattedDate);
          if (data) {
            setDrumsData(data);
          }
          setOpenUserPopup(false);
          setFormData({
            machine_name: "",
            drum_number: "",
            date: dayjs().format("YYYY-MM-DD"),

            start_time: null,
            end_time: null,
            drum_length: null,
          });
          toast.success("Drum Updated successfully");
        } else {
          toast.error("Drum Updating Failed");
        }
      } else {
  
        const response = await addDrums(
          formData.machine_name,
          formData.drum_number,
          formData.date,
          formData.start_time,
          formData.end_time,
          formData.drum_length
        );

        if (response.status) {
          const formattedDate = selectedDate.format("YYYY-MM-DD");
          const data = await fetchDrumsData(formattedDate);
          if (data) {
            setDrumsData(data);
          }
          setOpenUserPopup(false);
          setFormData({
            machine_name: "",
            drum_number: "",
            date: dayjs().format("YYYY-MM-DD"),

            start_time: null,
            end_time: null,
            drum_length: null,
          });
          toast.success("Drum added successfully");
        } else {
          toast.error("Drum number already used!");
        }
      }
    } catch (error) {
      console.error("Error adding drums:", error);
      // Check if the error message is due to drum number already used
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Drum number already used!"
      ) {
        toast.error("Drum number already used. Please use a different number.");
      } else {
        toast.error("Failed to add drums. Please try again.");
      }
    }
  };

  return (
    <div className="add-drum-main">
      <Toaster />
      <Dialog
        open={openDelete}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "502px",
            height: "350px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 40px",
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <img src={deletePopup} alt="" width={100} height={100} />
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure You Want to Delete ?"}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={handleCloseDeletePopup}
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              color: "#26408A",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              backgroundOrigin: "padding-box, border-box",
              backgroundClip: "padding-box, border-box",
              marginRight: "24px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundColor: "#BA1A1A",
              color: "#fff",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#BA1A1A",
                border: "2px solid #BA1A1A",
                textTransform: "capitalize",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUserPopup}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "471px",
            height: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <div className="adduser-nav">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <span style={{ color: "white" }}>Add Drum</span>
          </div>
          <div onClick={handleCancel} style={{ cursor: "pointer" }}>
            <img src={close} alt="" width={24} height={24} />
          </div>
        </div>
        <div
          style={{
            padding: "24px",
            width: "100%",
            height: "400px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <div>
            <span>Machine</span>
            <FormControl sx={{}} size="small" fullWidth>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="machine_name"
                value={formData.machine_name}
                onChange={handleChange}
                IconComponent={ExpandMore}
                required
                displayEmpty
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiSelect-icon": {
                    color: "#1A1B21",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Machine
                </MenuItem>
                <MenuItem value="RST5">RST5</MenuItem>
                <MenuItem value="RST6">RST6</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <span>Date</span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={dayjs(formData.date)}
                onChange={handleDate}
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "8.5px 14px !important",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <span>Start Time</span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                ampm={false}
                views={["hours", "minutes", "seconds"]}
                size="small"
                required
                value={
                  formData.start_time
                    ? dayjs(formData.start_time, "HH:mm:ss")
                    : null
                }
                onChange={(newValue) =>
                  handleTimeChange("start_time", newValue)
                }
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "8.5px 14px !important",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <span>End Time</span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                ampm={false}
                views={["hours", "minutes", "seconds"]}
                size="small"
                required
                value={
                  formData.end_time
                    ? dayjs(formData.end_time, "HH:mm:ss")
                    : null
                }
                onChange={(newValue) => handleTimeChange("end_time", newValue)}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.8)",
                  },
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "8.5px 14px !important",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <span>Drum Length (KM)</span>
            <TextField
              id="outlined-multiline-flexible"
              name="drum_length"
              fullWidth
              value={formData.drum_length}
              onChange={handleChange}
              size="small"
              required
              placeholder="Drum Length"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.8)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.8)",
                },
              }}
            />
          </div>
          <div>
            <span>Drum No</span>
            <TextField
              id="outlined-multiline-flexible"
              name="drum_number"
              fullWidth
              value={formData.drum_number}
              onChange={handleChange}
              size="small"
              required
              placeholder="Drum No"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.8)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.8)",
                },
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "24px",
            }}
          >
            <button
              onClick={handleCancel}
              style={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                color: "rgba(69, 69, 71, 1)",
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "126px",
                height: "40px",
                borderRadius: "20px",
                border: "1px solid rgba(69, 69, 71, 1)",
                backgroundOrigin: "padding-box, border-box",
                backgroundClip: "padding-box, border-box",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => handleAdd()}
              autoFocus
              style={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "126px",
                height: "40px",
                borderRadius: "20px",
                border: "2px solid transparent",
                backgroundColor: "rgba(69, 69, 71, 1)",
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              {edit ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </Dialog>
      <div className="add-drum-container">
        {" "}
        <div className="add-drum-header">
          <div className="add-drum-header-text">
            <div>
              <span className="header-brand">Total Records</span>
            </div>
            <div className="count-circle">
              <span className="added-drums-count">{drumsData.length}</span>
            </div>
          </div>
          <div className="drum-header-buttons">
            <select
              key="Machine"
              id="MachineDropdown"
              className="report-dropdown"
              onChange={handleMachineChange}
            >
              <option className="report-dropdown-option" value="">
                All RST'S
              </option>
              <option className="report-dropdown-option" value="RST5">
                RST5
              </option>
              <option className="report-dropdown-option" value="RST6">
                RST6
              </option>
            </select>
            <select
              key="Shift"
              id="ShiftDropdown"
              className="report-dropdown"
              onChange={handleShiftChange}
            >
              <option className="report-dropdown-option" value="">
                All Shifts
              </option>
              <option className="report-dropdown-option" value="Shift1">
                Shift 1
              </option>
              <option className="report-dropdown-option" value="Shift2">
                Shift 2
              </option>
              <option className="report-dropdown-option" value="Shift3">
                Shift 3
              </option>
            </select>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <StyledDatePicker
                  onChange={handleDateChange}
                  format="DD/MM/YYYY" // This formats the date as "DD/MM/YYYY"
                  value={selectedDate}
                  maxDate={dayjs()}
                  disabled={tableDataLoading}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "32px",
                      width: "115px",
                      padding: "2px 10px !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(224, 230, 238, 1) !important", // Change the border color to black
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                    },
                    "& .MuiTextField-root": {
                      minWidth: "180px", // Change the minimum width
                    },
                    "& .MuiInputBase-root.MuiOutlinedInput-root": {
                      paddingRight: "0px !important",
                      borderRadius: "7px !important",
                      border: "1px solid rgba(189, 200, 215, 1)",
                    },
                    "& .MuiButtonBase-root.MuiIconButton-root": {
                      backgroundColor: "rgba(64, 64, 242, 1)",
                      padding: "6px !important",
                      borderRadius: "0px 7px 7px 0px !important",
                      zIndex: 10,
                    },
                    "& .MuiSvgIcon-root": {
                      color: "white",
                    },
                    minWidth: "180px !important",
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <div>
              <button
                className="add-drum-button"
                onClick={() => setOpenUserPopup(true)}
              >
                <span className="header-button-text">+ Add Drum</span>
              </button>
            </div>
          </div>
        </div>
        {(drumsData.length === 0 || tableData.length) && tableDataLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 280px)",
              padding: "15rem",
              backgroundColor: "rgb(246, 248, 250)",
            }}
          >
            <CircularProgress />
          </div>
        ) : rows.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 280px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={empty} alt="" width={400} height={350} />
            <span style={{ fontSize: "24px", fontWeight: 500 }}>
              Drums have not been added yet.!
            </span>
          </div>
        ) : (
          <Paper className="drum-list-container">
            <TableVirtuoso
              data={rows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
            />
          </Paper>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  drumsData: state.drums.data,
  tableData: state.data.data,
  tableDataLoading: state.data.loading,
  drumDetails: state.drumDetails,
});

const mapDispatchToProps = {
  fetchDrumsData,
  addDrums,
  fetchData,
  fetchDrum,
  editDrum,
  deleteDrum,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
